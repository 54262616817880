import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowLeft,
  CalendarBlank,
  ClockCounterClockwise,
  CurrencyCircleDollar,
  Info,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import { generatePredictor } from "../../features/predictor/predictorSlice";
import PrimaryButton from "../../components/General/PrimaryButton";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment-jalaali";
import { formatDate } from "../../services/helpers";

const GeneratePredictor = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "فال و طالع‌بینی", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const [type, setType] = useState("1");
  const [userName, setUserName] = useState("");
  const [userPartnerName, setUserPartnerName] = useState("");
  const [birthDate, setBirthDate] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [toolPrice, setToolPrice] = useState("0");

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);

  const bottomButtonRef = useRef(null);

  const dispatch = useDispatch();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  const currentPredictor = useSelector((state) => state.predictor.current);

  useEffect(() => {
    if (toolsPrices) {
      const basePrice = toolsPrices?.predictor ?? 0;

      setToolPrice(basePrice);
    }
  }, [toolsPrices]);

  useEffect(() => {
    setBottomButtonHeight(
      bottomButtonRef.current?.getBoundingClientRect().height
    );
  }, []);

  useEffect(() => {
    if (submitted && currentPredictor?.id) {
      navigate(routes.predictorSingle?.replace(":id", currentPredictor?.id), {
        replace: true,
      });

      setSubmitted(false);
    }
  }, [currentPredictor?.id, navigate, submitted]);

  const handleGenerate = () => {
    const userNameVal = userName?.trim();
    const userPartnerNameVal = userPartnerName?.trim();

    if (type === "3" && (userNameVal === "" || userPartnerNameVal === "")) {
      dispatch(
        showAppSnackbar({
          severity: "error",
          text: "لطفا نام خود و پارتنر را وارد کنید.",
        })
      );
      return;
    }

    if (type === "4" && birthDate === "") {
      dispatch(
        showAppSnackbar({
          severity: "error",
          text: "لطفا تاریخ تولد خود را وارد کنید.",
        })
      );
      return;
    }

    setIsLoadingButton(true);

    dispatch(
      generatePredictor({
        type: type,
        userName: userNameVal,
        userPartnerName: userPartnerNameVal,
        birthDate: birthDate,
      })
    ).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  return (
    <Container maxWidth={"xs"} sx={{ p: 2, position: "relative" }}>
      <Stack gap={2}>
        <Box>
          <Typography fontWeight={600} mb={1.5}>
            نوع فال
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              فال حافظ
            </MenuItem>

            <MenuItem key={2} value="2">
              فال انبیا
            </MenuItem>

            <MenuItem key={3} value="3">
              فال ابجد عشق
            </MenuItem>

            <MenuItem key={4} value="4">
              فال روز تولد
            </MenuItem>
          </Select>
        </Box>

        {type === "3" ? (
          <>
            <Box>
              <Typography fontWeight={600} mb={1.5}>
                نام شما
              </Typography>

              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    bgcolor: "background.paper",
                  },
                }}
                type="text"
                variant="outlined"
                fullWidth
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Box>

            <Box>
              <Typography fontWeight={600} mb={1.5}>
                نام پارتنر یا همسر شما
              </Typography>

              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    bgcolor: "background.paper",
                  },
                }}
                type="text"
                variant="outlined"
                fullWidth
                value={userPartnerName}
                onChange={(e) => setUserPartnerName(e.target.value)}
              />
            </Box>
          </>
        ) : null}

        {type === "4" ? (
          <Box>
            <Typography fontWeight={600} mb={1.5}>
              تاریخ تولد شما
            </Typography>

            <DatePicker
              slotProps={{
                textField: { fullWidth: true },
              }}
              slots={{ openPickerIcon: CalendarBlank }}
              maxDate={moment().subtract(10, "year").toDate()}
              value={
                birthDate?.length
                  ? moment(birthDate, "YYYY-MM-DD").toDate()
                  : null
              }
              onChange={(value) => setBirthDate(formatDate(value))}
            />
          </Box>
        ) : null}

        {type === "1" || type === "2" ? (
          <Box>
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <Box fontSize={0} sx={{ position: "relative", top: 2 }}>
                <Info size={16} />
              </Box>

              <Typography variant="body2" fontSize={13}>
                چشمان خود را بسته و با حضور ذهن سوره حمد را بخوانید حال آنچه را
                که مایلید قصد و نیت کنید آنگاه برای گرفتن فال، روی ایجاد فال
                کلیک کنید.
              </Typography>
            </Stack>
          </Box>
        ) : null}

        {type === "3" ? (
          <Box>
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <Box fontSize={0} sx={{ position: "relative", top: 2 }}>
                <Info size={16} />
              </Box>

              <Typography variant="body2" fontSize={13}>
                فال ابجد عشق، بر اساس ترکیب اعداد حروف ابجد نام شما و پارتنر یا
                همسر شما ایجاد میشود.
              </Typography>
            </Stack>
          </Box>
        ) : null}
      </Stack>

      <Box sx={{ height: bottomButtonHeight }} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
          bgcolor: "background.default",
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} gap={0.5} mb={2}>
          <Box sx={{ color: "primary.main" }} fontSize={0}>
            <CurrencyCircleDollar size={18} />
          </Box>

          <Typography variant="body2" fontSize={13} textAlign={"center"}>
            هزینه ایجاد فال: <b>{toolPrice}</b> اعتبار
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Link to={routes.predictorHistory}>
            <Box sx={{ color: "text.primary" }} fontSize={0}>
              <ClockCounterClockwise size={28} />
            </Box>
          </Link>

          <PrimaryButton
            isLoading={isLoadingButton}
            endIcon={<ArrowLeft weight="bold" size={20} />}
            onClick={handleGenerate}
          >
            ایجاد فال
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default GeneratePredictor;
