import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "./config/store";
import router from "./config/routes";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { cacheRtl, darkTheme, lightTheme } from "./config/theme";
import { CssBaseline } from "@mui/material";

import AppDialog from "./components/General/AppDialog";
import AppSnackbar from "./components/General/AppSnackbar";
import AppProvider from "./AppProvider";

const App = () => {
  const [themeScheme, setThemeScheme] = useState(lightTheme);

  function storageEventHandler(event) {
    if (event.key === "preferences") {
      const preferences = JSON.parse(localStorage.getItem("preferences"));
      if (preferences && preferences.theme) {
        setThemeScheme(preferences.theme === "dark" ? darkTheme : lightTheme);
      }
    }
  }

  useEffect(() => {
    const preferences = JSON.parse(localStorage.getItem("preferences"));
    if (preferences && preferences.theme) {
      setThemeScheme(preferences.theme === "dark" ? darkTheme : lightTheme);
    }

    window.addEventListener("storage", storageEventHandler);

    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <Provider store={store}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themeScheme}>
          <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
            <AppProvider>
              <RouterProvider router={router} />
              <CssBaseline />
              <AppDialog />
              <AppSnackbar />
            </AppProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
};

export default App;
