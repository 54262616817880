import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  history: [],
  current: null,
};

export const generateMovieSuggester = createAsyncThunk(
  "movieSuggester/generateMovieSuggester",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/movie-suggester/requests`,
        data: {
          type: payload?.type ?? null,
          genre: payload?.genre ?? null,
          country: payload?.country ?? null,
          favoriteMovies: payload?.favoriteMovies ?? null,
          favoriteActors: payload?.favoriteActors ?? null,
          favoriteSubject: payload?.favoriteSubject ?? null,
          suggestionCount: payload?.suggestionCount ?? 3,
        },
      });

      thunkApi.dispatch(saveCurrentMovieSuggester(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reGenerateMovieSuggester = createAsyncThunk(
  "movieSuggester/reGenerateMovieSuggester",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/movie-suggester/requests/${payload.id}/regenerate`,
      });

      thunkApi.dispatch(saveCurrentMovieSuggester(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllMovieSuggesterHistory = createAsyncThunk(
  "movieSuggester/fetchAllMovieSuggesterHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/movie-suggester/requests?page=${payload.page}&limit=${payload.limit}`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchMovieSuggester = createAsyncThunk(
  "movieSuggester/fetchMovieSuggester",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/movie-suggester/requests/${payload.id}`,
      });

      thunkApi.dispatch(saveCurrentMovieSuggester(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteMovieSuggester = createAsyncThunk(
  "movieSuggester/deleteMovieSuggester",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/movie-suggester/requests`,
        data: { ids: [payload.id] },
      });

      thunkApi.dispatch(cleanupCurrentMovieSuggester());

      return payload.id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearAllMovieSuggesterHistory = createAsyncThunk(
  "movieSuggester/clearAllMovieSuggesterHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/movie-suggester/requests`,
        data: { ids: null },
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCurrentMovieSuggester = createAction(
  "movieSuggester/saveCurrentMovieSuggester"
);

export const cleanupCurrentMovieSuggester = createAction(
  "movieSuggester/cleanupCurrentMovieSuggester"
);

const movieSuggesterSlice = createSlice({
  initialState,
  name: "movieSuggester",
  extraReducers: (builder) => {
    builder.addCase(saveCurrentMovieSuggester, (state, action) => {
      state.current = action.payload;
    });

    builder.addCase(cleanupCurrentMovieSuggester, (state) => {
      state.current = initialState.current;
    });

    builder.addCase(
      fetchAllMovieSuggesterHistory.fulfilled,
      (state, action) => {
        state.history = action.payload?.rows;
      }
    );

    builder.addCase(clearAllMovieSuggesterHistory.fulfilled, (state) => {
      state.history = [];
    });

    builder.addCase(deleteMovieSuggester.fulfilled, (state, action) => {
      state.history = state.history.filter((item) => item?.id !== action);
    });
  },
});

export default movieSuggesterSlice.reducer;
