import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hideAppSnackbar } from "../../features/general/snackbarSlice";

const AppSnackbar = () => {
  const appSnackbar = useSelector((state) => state.appSnackbar);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(appSnackbar.open);
  }, [appSnackbar.open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hideAppSnackbar());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={appSnackbar.time}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={appSnackbar.severity ?? "error"}
        sx={{ width: "100%" }}
      >
        {appSnackbar.text ?? ""}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
