import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hideAppDialog } from "../../features/general/dialogSlice";
import SecondaryButton from "./SecondaryButton";

const AppDialog = () => {
  const appDialog = useSelector((state) => state.appDialog);

  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(appDialog.open);

  useEffect(() => {
    setDialogOpen(appDialog.open);
  }, [appDialog.open]);

  const handleClose = () => {
    dispatch(hideAppDialog());
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="body1">{appDialog.title}</Typography>
      </DialogTitle>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={handleClose}>
          {appDialog.rejectText ?? "فعلا نه!"}
        </Button>

        <SecondaryButton
          fullWidth={false}
          onClick={appDialog.acceptCallback ?? handleClose}
        >
          {appDialog.acceptText ?? "تایید"}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default AppDialog;
