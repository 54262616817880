import { Box, Card, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { numberWithCommas } from "../../services/helpers";
import moment from "moment-jalaali";
import { cleanupCryptoCurrencyPricesList } from "../../features/currency_price_informer/currencyPriceInformerSlice";

const CryptoListResult = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "آخرین قیمت‌ ارز های دیجیتال", hasBack: true })
    );
  }, []);

  const cryptoList = useSelector(
    (state) => state.currenyPriceInformer.cryptoList
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (cryptoList.length === 0) {
      navigate(routes.root);
    }

    return () => dispatch(cleanupCryptoCurrencyPricesList());
  }, []);

  return (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack gap={2}>
        {cryptoList.map((item, i) => (
          <Card sx={{ p: 2 }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <Stack gap={1.5}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Box component={"img"} src={item?.logo} height={30} />

                  <Typography variant="body2" fontWeight={700}>
                    {item?.name} ({item?.symbol})
                  </Typography>
                </Stack>

                <Typography variant="body2" fontWeight={300} fontSize={13}>
                  آخرین بروزرسانی:{" "}
                  {item?.lastUpdate?.length > 0 &&
                    moment(item?.lastUpdate).format("jYYYY/jM/jD - HH:mm")}
                </Typography>
              </Stack>

              <Stack gap={1.5} alignItems={"flex-end"}>
                <Typography variant="body2">
                  {numberWithCommas(item?.quote?.irr?.price / 10 ?? 0)}{" "}
                  <Typography variant="caption" fontSize={14} fontWeight={700}>
                    تومان
                  </Typography>
                </Typography>

                <Typography variant="body2">
                  {numberWithCommas(item?.quote?.usdt?.price ?? 0)}{" "}
                  <Typography variant="caption" fontSize={14} fontWeight={700}>
                    تتر
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          </Card>
        ))}
      </Stack>
    </Container>
  );
};

export default CryptoListResult;
