import {
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllMovieSuggesterHistory,
  fetchAllMovieSuggesterHistory,
} from "../../features/movie_suggester/movieSuggesterSlice";
import { TrashSimple } from "@phosphor-icons/react/dist/ssr";
import {
  hideAppDialog,
  showAppDialog,
} from "../../features/general/dialogSlice";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import moment from "moment-jalaali";
import { movieSuggesterMovieGenreies, movieSuggesterMovieMakerCountries } from "../../config/constants";

const MovieSuggesterHistory = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "تاریخچه پیشنهاد فیلم", hasBack: true })
    );
  }, []);

  const dispatch = useDispatch();

  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const movieSuggesterHistory = useSelector(
    (state) => state.movieSuggester.history
  );

  useEffect(() => {
    dispatch(fetchAllMovieSuggesterHistory({ page: 0, limit: 100 })).then(
      (res) => setIsLoadingPage(false)
    );
  }, []);

  const handleClearHistory = () => {
    dispatch(
      showAppDialog({
        title: "آیا از حذف تاریخچه پیشنهاد فیلم اطمینان دارید؟",
        acceptCallback: () => {
          dispatch(clearAllMovieSuggesterHistory()).then((res) => {
            if (res.error === undefined) {
              dispatch(
                showAppSnackbar({
                  severity: "success",
                  text: "با موفقیت حذف شد.",
                })
              );
            }
          });

          dispatch(hideAppDialog());
        },
      })
    );
  };

  return isLoadingPage ? (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack
        width={"100%"}
        height={`calc(100vh - 66px)`}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={26} />
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      {movieSuggesterHistory?.length === 0 ? (
        <Typography>موردی پیدا نشد.</Typography>
      ) : (
        <Box>
          <Stack
            component={"div"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={0.5}
            mb={2}
            onClick={handleClearHistory}
          >
            <Typography
              variant="body2"
              fontSize={13}
              textAlign={"right"}
              sx={{ color: "error.main" }}
            >
              پاک کردن تاریخچه
            </Typography>

            <Box sx={{ color: "error.main" }} fontSize={0}>
              <TrashSimple size={16} />
            </Box>
          </Stack>

          <Stack gap={2}>
            {movieSuggesterHistory?.map((historyItem) => (
              <Link
                to={routes.movieSuggesterSingle.replace(":id", historyItem?.id)}
              >
                <Card key={0} sx={{ py: 1, px: 2 }}>
                  <Stack gap={1.5}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "100%",
                      }}
                    >
                      {historyItem?.type === 1
                        ? "سینمایی"
                        : historyItem?.type === 2
                        ? "سریال"
                        : "سینمایی و سریال"}{" "}
                      - {movieSuggesterMovieGenreies[historyItem?.genre ?? 0]} -{" "}
                      {
                        movieSuggesterMovieMakerCountries[
                          historyItem?.country ?? 0
                        ]
                      }
                    </Typography>

                    <Typography variant="body2" fontWeight={300}>
                      {historyItem?.createdAt?.length > 0 &&
                        moment(historyItem?.createdAt).format(
                          "jYYYY/jM/jD - HH:mm"
                        )}
                    </Typography>
                  </Stack>
                </Card>
              </Link>
            ))}
          </Stack>
        </Box>
      )}
    </Container>
  );
};

export default MovieSuggesterHistory;
