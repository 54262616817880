import { Card, Container, Typography } from "@mui/material";
import {
  Coins,
  Exam,
  Feather,
  FilmSlate,
  InstagramLogo,
  MoonStars,
  ShootingStar,
} from "@phosphor-icons/react";
import { Masonry } from "@mui/lab";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import { useEffect, useState } from "react";

const Root = () => {
  const [tools, setTools] = useState([
    {
      title: "تعبیر خواب",
      desc: "تعبیر خواب های شما با کمک هوش مصنوعی.",
      icon: ShootingStar,
      link: routes.generateDreamInterpreter,
      background: "linear-gradient(180deg, #1FB77B, #0A8B58)",
      height: 160,
    },
    {
      title: "آزمون زبان",
      desc: "آزمون زبان با سوالات متنوع و چهار گزینه‌ای متناسب با سطح شما.",
      icon: Exam,
      link: routes.generateLanguageExaminer,
      background: "linear-gradient(180deg, #6AC1FF, #0295FF)",
      height: 200,
    },
    {
      title: "نامه نویس",
      desc: "نوشتن انواع نامه‌ها و متون مناسبتی برای ایمیل یا در پاسخ افراد.",
      icon: Feather,
      link: routes.generateLetterWriter,
      background: "linear-gradient(180deg, #FF7121, #E25100)",
      height: 200,
    },
    {
      title: "ایجاد پست",
      desc: "ایجاد پست برای شبکه های اجتماعی اینستاگرام و لینکدین.",
      icon: InstagramLogo,
      link: routes.generateSocialContentCreator,
      background: "linear-gradient(180deg, #CE7EFF, #A12FE7)",
      height: 160,
    },
    {
      title: "فال و طالع‌بینی",
      desc: "فال های متنوع حافظ، انبیا، روز تولد و ابجد عشق را امتحان کنید",
      icon: MoonStars,
      link: routes.generatePredictor,
      background: "linear-gradient(180deg, #78BD33, #448700)",
      height: 160,
    },
    {
      title: "قیمت‌های بازار",
      desc: "دریافت آخرین قیمت‌های بازار ارز، طلا، سکه و ارز دیجیتال.",
      icon: Coins,
      link: routes.generateCurrencyPriceInformer,
      background: "linear-gradient(180deg, #3D67C5, #1549B9)",
      height: 200,
    },
    {
      title: "پیشنهاد فیلم",
      desc: "فیلم مورد علاقه بعدیتان را بر اساس سلیقه خود پیدا کنید.",
      icon: FilmSlate,
      link: routes.generateMovieSuggester,
      background: "linear-gradient(180deg, #FFB905, #D69A00)",
      height: 200,
    },
  ]);

  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "ابزارها", hasBack: false })
    );
  }, []);

  return (
    <Container maxWidth={"xs"} sx={{ py: 2 }}>
      <Masonry columns={2} spacing={2} sx={{ mx: "auto" }}>
        {tools.map((tool, i) => (
          <Link to={tool.link}>
            <Card
              key={i}
              sx={{
                background: tool.background,
                height: tool.height,
                color: "white",
                p: 1.5,
                borderRadius: "17px",
              }}
            >
              <Stack height={"100%"} gap={2} justifyContent={"space-between"}>
                <Stack gap={1}>
                  <Typography variant="subtitle1">{tool.title}</Typography>
                  <Typography variant="body2" fontSize={12}>
                    {tool.desc}
                  </Typography>
                </Stack>

                <Stack direction={"row"} justifyContent={"flex-end"}>
                  <tool.icon size={28} />
                </Stack>
              </Stack>
            </Card>
          </Link>
        ))}
      </Masonry>
    </Container>
  );
};

export default Root;
