import { isLocalEnv } from "../services/helpers";

export const apiUrl = isLocalEnv()
  ? "https://dev.parssist.com"
  : "https://api.parssist.com";

export const movieSuggesterMovieGenreies = [
  "همه ژانرها",
  "اکشن",
  "ترسناک",
  "علمی تخیلی",
  "درام",
  "هیجانی",
  "عاشقانه",
  "جنایی",
  "کمدی",
  "وسترن",
  "مستند",
  "فانتزی",
  "ماجراجویی",
  "موزیکال",
  "انیمیشن",
  "ورزشی",
  "زندگی‌نامه",
  "بی‌صدا",
  "مخصوص کودکان",
];

export const movieSuggesterMovieMakerCountries = [
  "همه کشورها",
  "آمریکا",
  "ایران",
  "هند",
  "انگلیس",
  "آلمان",
  "اسپانیا",
  "ایتالیا",
  "فرانسه",
  "کره جنوبی",
  "ژاپن",
  "ترکیه",
];
