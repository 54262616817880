import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  history: [],
  current: {
    id: null,
    language: null,
    questionsLanguage: null,
    questions: [],
    questionsCount: 0,
    userAnswers: [],
    answerSheet: [],
  },
};

export const generateLanguageExaminer = createAsyncThunk(
  "languageExaminer/generateLanguageExaminer",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/language-examiner/requests`,
        data: {
          questionsCount: payload.questionsCount,
          testLanguage: payload.testLanguage,
          category: payload.category,
          questionsLanguage: payload.questionsLanguage,
          level: payload.level,
        },
      });

      thunkApi.dispatch(saveCurrentLanguageExaminer(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reGenerateLanguageExaminer = createAsyncThunk(
  "languageExaminer/reGenerateLanguageExaminer",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/language-examiner/requests/${payload.id}/regenerate`,
      });

      thunkApi.dispatch(saveCurrentLanguageExaminer(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const submitAnswersLanguageExaminer = createAsyncThunk(
  "languageExaminer/submitAnswersLanguageExaminer",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "put",
        url: `/app/tools/language-examiner/requests/${payload.id}/submit-answers`,
        data: {
          answers: payload.answers,
        },
      });

      thunkApi.dispatch(saveCurrentLanguageExaminer(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllLanguageExaminerHistory = createAsyncThunk(
  "languageExaminer/fetchAllLanguageExaminerHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/language-examiner/requests?page=${payload.page}&limit=${payload.limit}`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchLanguageExaminer = createAsyncThunk(
  "languageExaminer/fetchLanguageExaminer",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/language-examiner/requests/${payload.id}`,
      });

      thunkApi.dispatch(saveCurrentLanguageExaminer(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteLanguageExaminer = createAsyncThunk(
  "languageExaminer/deleteLanguageExaminer",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/language-examiner/requests`,
        data: { ids: [payload.id] },
      });

      thunkApi.dispatch(cleanupCurrentLanguageExaminer());

      return payload.id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearAllLanguageExaminerHistory = createAsyncThunk(
  "languageExaminer/clearAllLanguageExaminerHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/language-examiner/requests`,
        data: { ids: null },
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateUserAnswers = createAction(
  "languageExaminer/updateUserAnswers"
);

export const saveCurrentLanguageExaminer = createAction(
  "languageExaminer/saveCurrentLanguageExaminer"
);

export const cleanupCurrentLanguageExaminer = createAction(
  "languageExaminer/cleanupCurrentLanguageExaminer"
);

const languageExaminerSlice = createSlice({
  initialState,
  name: "languageExaminer",
  extraReducers: (builder) => {
    builder.addCase(saveCurrentLanguageExaminer, (state, action) => {
      state.current.id = action.payload?.id;
      state.current.language = action.payload?.testLanguage;
      state.current.questionsLanguage = action.payload?.questionsLanguage;
      state.current.questions = action.payload?.questions;
      state.current.questionsCount = action.payload?.questionsCount;
      state.current.userAnswers = action.payload?.userAnswers ?? [];
      state.current.answerSheet = action.payload?.answerSheet ?? [];
    });

    builder.addCase(cleanupCurrentLanguageExaminer, (state) => {
      state.current.id = initialState.current.id;
      state.current.questions = initialState.current.questions;
      state.current.userAnswers = initialState.current.userAnswers;
      state.current.answerSheet = initialState.current.answerSheet;
      state.current.questionsCount = initialState.current.questionsCount;
    });

    builder.addCase(
      fetchAllLanguageExaminerHistory.fulfilled,
      (state, action) => {
        state.history = action.payload?.rows;
      }
    );

    builder.addCase(clearAllLanguageExaminerHistory.fulfilled, (state) => {
      state.history = [];
    });

    builder.addCase(deleteLanguageExaminer.fulfilled, (state, action) => {
      state.history = state.history.filter((item) => item?.id !== action);
    });

    builder.addCase(updateUserAnswers, (state, action) => {
      state.current.userAnswers[action.payload.index] = action.payload.option;
    });
  },
});

export default languageExaminerSlice.reducer;
