import { Button, CircularProgress } from "@mui/material";

const PrimaryButton = ({
  sx,
  onClick,
  endIcon,
  isLoading = false,
  children,
  fullWidth = true,
  size = "large",
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size={size}
      sx={
        sx ?? {
          py: 1.25,
          borderRadius: "30px",
          fontWeight: 700,
          "&.MuiButton-root:hover": { bgcolor: "primary.main" },
          opacity: isLoading ? 0.5 : 1,
          cursor: isLoading ? "default" : "pointer",
        }
      }
      endIcon={
        isLoading ? (
          <CircularProgress sx={{ color: "white" }} size={20} />
        ) : (
          endIcon
        )
      }
      fullWidth={fullWidth}
      onClick={isLoading ? null : onClick}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
