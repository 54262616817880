import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowLeft,
  ClockCounterClockwise,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { generateLanguageExaminer } from "../../features/language_examiner/languageExaminerSlice";
import PrimaryButton from "../../components/General/PrimaryButton";

const GenerateLanguageExaminer = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "آزمون زبان", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const [language, setLanguage] = useState("1");
  const [level, setLevel] = useState("2");
  const [questionsLanguage, setQuestionsLanguage] = useState("2");
  const [category, setCategory] = useState("3");
  const [questionsNumber, setQuestionsNumber] = useState("10");

  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [toolPrice, setToolPrice] = useState("0");

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);

  const bottomButtonRef = useRef(null);

  const dispatch = useDispatch();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  const currentLanguageExaminer = useSelector(
    (state) => state.languageExaminer.current
  );

  useEffect(() => {
    if (toolsPrices) {
      const basePrice = toolsPrices?.languageExaminer?.base ?? 0;

      const questionsLanguagePrice =
        toolsPrices?.languageExaminer?.extra?.questionsLanguage[
          `${questionsLanguage}`
        ] ?? 0;

      const languagePrice =
        toolsPrices?.languageExaminer?.extra?.testLanguage[`${language}`] ?? 0;

      const questionsNumberPrice =
        (toolsPrices?.languageExaminer?.extra?.questionsCountCoefficient ?? 0) *
        questionsNumber;

      setToolPrice(
        basePrice +
          questionsLanguagePrice +
          languagePrice +
          questionsNumberPrice
      );
    }
  }, [language, questionsLanguage, questionsNumber, toolsPrices]);

  useEffect(() => {
    setBottomButtonHeight(
      bottomButtonRef.current?.getBoundingClientRect().height
    );
  }, []);

  useEffect(() => {
    if (submitted && currentLanguageExaminer?.id) {
      navigate(
        routes.languageExaminerQuestions
          ?.replace(":id", currentLanguageExaminer?.id)
          .replace(":questionId", 1),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentLanguageExaminer?.id, navigate, submitted]);

  const handleGenerate = () => {
    setIsLoadingButton(true);

    dispatch(
      generateLanguageExaminer({
        questionsCount: questionsNumber,
        testLanguage: language,
        category: category,
        questionsLanguage: questionsLanguage,
        level: level,
      })
    ).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  return (
    <Container maxWidth={"xs"} sx={{ p: 2, position: "relative" }}>
      <Stack gap={2}>
        <Box>
          <Typography fontWeight={600} mb={1.5}>
            زبان
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              انگلیسی
            </MenuItem>

            <MenuItem key={2} value="2">
              عربی
            </MenuItem>

            <MenuItem key={3} value="3">
              آلمانی
            </MenuItem>

            <MenuItem key={4} value="4">
              فرانسوی
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            سطح
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              مبتدی
            </MenuItem>

            <MenuItem key={2} value="2">
              متوسط
            </MenuItem>

            <MenuItem key={3} value="3">
              پیشرفته
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            بخش
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              خوانش - Reading
            </MenuItem>

            <MenuItem key={2} value="2">
              لغات - Vocabulary
            </MenuItem>

            <MenuItem key={3} value="3">
              گرامر - Grammar
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            زبان صورت سوالات
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={questionsLanguage}
            onChange={(e) => setQuestionsLanguage(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              فارسی
            </MenuItem>

            <MenuItem key={2} value="2">
              زبان اصلی آزمون
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            تعداد سوال
          </Typography>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                bgcolor: "background.paper",
              },
            }}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 20 } }}
            variant="outlined"
            fullWidth
            value={questionsNumber}
            onChange={(e) => setQuestionsNumber(e.target.value)}
          />
        </Box>
      </Stack>

      <Box sx={{ height: bottomButtonHeight }} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
          bgcolor: "background.default",
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} gap={0.5} mb={2}>
          <Box sx={{ color: "primary.main" }} fontSize={0}>
            <CurrencyCircleDollar size={18} />
          </Box>

          <Typography variant="body2" fontSize={13} textAlign={"center"}>
            هزینه ایجاد آزمون: <b>{toolPrice}</b> اعتبار
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Link to={routes.languageExaminerHistory}>
            <Box sx={{ color: "text.primary" }} fontSize={0}>
              <ClockCounterClockwise size={28} />
            </Box>
          </Link>

          <PrimaryButton
            isLoading={isLoadingButton}
            endIcon={<ArrowLeft weight="bold" size={20} />}
            onClick={handleGenerate}
          >
            ایجاد و شروع آزمون
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default GenerateLanguageExaminer;
