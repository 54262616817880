import {
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllLanguageExaminerHistory,
  fetchAllLanguageExaminerHistory,
} from "../../features/language_examiner/languageExaminerSlice";
import { TrashSimple } from "@phosphor-icons/react/dist/ssr";
import {
  hideAppDialog,
  showAppDialog,
} from "../../features/general/dialogSlice";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import moment from "moment-jalaali";

const languages = ["انگلیسی", "عربی", "آلمانی", "فرانسوی"];
const categories = ["خوانش - Reading", "لغات - Vocabulary", "گرامر - Grammar"];
const levels = ["مبتدی", "متوسط", "پیشرفته"];

const LanguageExaminerHistory = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "تاریخچه آزمون زبان", hasBack: true })
    );
  }, []);

  const dispatch = useDispatch();

  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const languageExaminerHistory = useSelector(
    (state) => state.languageExaminer.history
  );

  useEffect(() => {
    dispatch(fetchAllLanguageExaminerHistory({ page: 0, limit: 100 })).then(
      (res) => setIsLoadingPage(false)
    );
  }, [dispatch]);

  const handleClearHistory = () => {
    dispatch(
      showAppDialog({
        title: "آیا از حذف تاریخچه آزمون زبان اطمینان دارید؟",
        acceptCallback: () => {
          dispatch(clearAllLanguageExaminerHistory()).then((res) => {
            if (res.error === undefined) {
              dispatch(
                showAppSnackbar({
                  severity: "success",
                  text: "با موفقیت حذف شد.",
                })
              );
            }
          });

          dispatch(hideAppDialog());
        },
      })
    );
  };

  return isLoadingPage ? (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack
        width={"100%"}
        height={`calc(100vh - 66px)`}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={26} />
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" sx={{ p: 2}}>
      {languageExaminerHistory?.length === 0 ? (
        <Typography>موردی پیدا نشد.</Typography>
      ) : (
        <Box>
          <Stack
            component={"div"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={0.5}
            mb={2}
            onClick={handleClearHistory}
          >
            <Typography
              variant="body2"
              fontSize={13}
              textAlign={"right"}
              sx={{ color: "error.main" }}
            >
              پاک کردن تاریخچه
            </Typography>

            <Box sx={{ color: "error.main" }} fontSize={0}>
              <TrashSimple size={16} />
            </Box>
          </Stack>

          <Stack gap={2}>
            {languageExaminerHistory?.map((historyItem) => (
              <Link
                to={
                  historyItem?.userAnswers !== null
                    ? routes.languageExaminerSingle.replace(
                        ":id",
                        historyItem?.id
                      )
                    : routes.languageExaminerQuestions
                        .replace(":id", historyItem?.id)
                        .replace(":questionId", 1)
                }
              >
                <Card key={0} sx={{ py: 1, px: 2 }}>
                  <Stack gap={1.5}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "100%",
                      }}
                    >
                      آزمون زبان{" "}
                      {languages[(historyItem?.testLanguage ?? 0) - 1]} - بخش{" "}
                      {categories[(historyItem?.category ?? 0) - 1]} - سطح{" "}
                      {levels[(historyItem?.level ?? 0) - 1]}
                    </Typography>

                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="body2" fontWeight={300}>
                        {historyItem?.createdAt?.length > 0 &&
                          moment(historyItem?.createdAt).format(
                            "jYYYY/jM/jD - HH:mm"
                          )}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            historyItem?.userAnswers !== null
                              ? "success.main"
                              : "error.main",
                        }}
                      >
                        {historyItem?.userAnswers !== null
                          ? "تکمیل شده"
                          : "تکمیل نشده"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Link>
            ))}
          </Stack>
        </Box>
      )}
    </Container>
  );
};

export default LanguageExaminerHistory;
