import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowLeft,
  ClockCounterClockwise,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import { generateDreamInterpreter } from "../../features/dream_interpreter/dreamInterpreterSlice";
import PrimaryButton from "../../components/General/PrimaryButton";

const GenerateDreamInterpreter = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "تعبیر خواب", hasBack: true })
    );
  }, []);

  const [subject, setSubject] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [toolPrice, setToolPrice] = useState("0");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  const currentDreamInterpreter = useSelector(
    (state) => state.dreamInterpreter.current
  );

  useEffect(() => {
    if (toolsPrices) {
      setToolPrice(toolsPrices?.dreamInterpreter ?? "0");
    }
  }, [toolsPrices]);

  useEffect(() => {
    if (submitted && currentDreamInterpreter?.id) {
      navigate(
        routes.dreamInterpreterSingle?.replace(
          ":id",
          currentDreamInterpreter?.id
        ),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentDreamInterpreter?.id, navigate, submitted]);

  const handleGenerate = () => {
    const subjectValue = subject?.trim();

    if (subjectValue === "") {
      dispatch(
        showAppSnackbar({
          severity: "error",
          text: "لطفا موضوع  را وارد کنید.",
        })
      );
      return;
    }

    setIsLoadingButton(true);

    dispatch(generateDreamInterpreter({ dream: subjectValue })).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  return (
    <Container maxWidth={"xs"} sx={{ p: 2, position: "relative" }}>
      <Typography fontWeight={600} mb={1.5}>
        موضوع
      </Typography>

      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            bgcolor: "background.paper",
          },
        }}
        type="text"
        multiline
        rows={3}
        variant="outlined"
        placeholder="موضوع یا خوابی که دیده‌اید را تعریف کنید..."
        fullWidth
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} gap={0.5} mb={2}>
          <Box sx={{ color: "primary.main" }} fontSize={0}>
            <CurrencyCircleDollar size={18} />
          </Box>

          <Typography variant="body2" fontSize={13} textAlign={"center"}>
            هزینه دریافت تعبیر خواب: <b>{toolPrice}</b> اعتبار
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Link to={routes.dreamInterpreterHistory}>
            <Box sx={{ color: "text.primary" }} fontSize={0}>
              <ClockCounterClockwise size={28} />
            </Box>
          </Link>

          <PrimaryButton
            isLoading={isLoadingButton}
            endIcon={<ArrowLeft weight="bold" size={20} />}
            onClick={handleGenerate}
          >
            دریافت تعبیر خواب
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default GenerateDreamInterpreter;
