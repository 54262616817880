import {
  Box,
  Card,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArrowCounterClockwise,
  Copy,
  ShareNetwork,
  TrashSimple,
} from "@phosphor-icons/react";
import Markdown from "markdown-to-jsx";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../config/routes";
import {
  deleteLetterWriter,
  fetchLetterWriter,
  reGenerateLetterWriter,
} from "../../features/letter_writer/letterWriterSlice";
import {
  hideAppDialog,
  showAppDialog,
} from "../../features/general/dialogSlice";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import moment from "moment-jalaali";
import PrimaryButton from "../../components/General/PrimaryButton";
import MarkdownFormat from "../../components/General/MarkdownFormat";

const LetterWriterSingle = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "نامه نویس", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const { id: singleId } = useParams();

  const currentLetterWriter = useSelector(
    (state) => state.letterWriter.current
  );

  const bottomButtonRef = useRef(null);

  useEffect(() => {
    if (!isLoadingPage) {
      setBottomButtonHeight(
        bottomButtonRef.current?.getBoundingClientRect().height
      );
    }
  }, [isLoadingPage]);

  useEffect(() => {
    if (submitted && currentLetterWriter?.id) {
      navigate(
        routes.letterWriterSingle?.replace(":id", currentLetterWriter?.id),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentLetterWriter?.id, navigate, submitted]);

  useEffect(() => {
    if (currentLetterWriter?.id !== singleId) {
      dispatch(fetchLetterWriter({ id: singleId })).then((res) => {
        if (res.error !== undefined) {
          navigate(routes.root);
        } else {
          setIsLoadingPage(false);
        }
      });
    } else {
      setIsLoadingPage(false);
    }
  }, []);

  const handleShare = () => {
    window.ShareContentChannel?.postMessage(
      JSON.stringify({ text: currentLetterWriter?.result })
    );
  };

  const handleCopy = () => {
    window.CopyContentChannel?.postMessage(
      JSON.stringify({ text: currentLetterWriter?.result })
    );
  };

  const handleDelete = () => {
    dispatch(
      showAppDialog({
        title: "آیا از حذف این مورد اطمینان دارید؟",
        acceptCallback: () => {
          dispatch(deleteLetterWriter({ id: currentLetterWriter?.id })).then(
            (res) => {
              if (res.error === undefined) {
                dispatch(
                  showAppSnackbar({
                    severity: "success",
                    text: "با موفقیت حذف شد.",
                  })
                );

                navigate(routes.root, { replace: true });
              }
            }
          );

          dispatch(hideAppDialog());
        },
      })
    );
  };

  const handleReGenerate = () => {
    dispatch(
      showAppDialog({
        title: "آیا از ایجاد دوباره این مورد اطمینان دارید؟",
        acceptCallback: () => {
          setIsLoadingButton(true);

          dispatch(
            reGenerateLetterWriter({ id: currentLetterWriter?.id })
          ).then((res) => {
            setIsLoadingButton(false);

            if (res.error === undefined) {
              setSubmitted(true);

              window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
            }
          });

          dispatch(hideAppDialog());
        },
      })
    );
  };

  return isLoadingPage ? (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack
        width={"100%"}
        height={`calc(100vh - 66px)`}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={26} />
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Card sx={{ p: 2, mb: 2 }}>
        <Typography variant="body2">{currentLetterWriter?.subject}</Typography>
      </Card>

      <Card sx={{ p: 2 }}>
        <Stack gap={2}>
          {currentLetterWriter?.result !== undefined && (
            <MarkdownFormat>{currentLetterWriter?.result}</MarkdownFormat>
          )}

          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={2}
          >
            <Typography variant="body2" fontWeight={300} mr={"auto"}>
              {currentLetterWriter?.createdAt?.length > 0 &&
                moment(currentLetterWriter?.createdAt).format(
                  "jYYYY/jM/jD - HH:mm"
                )}
            </Typography>

            <IconButton sx={{ p: 0 }} onClick={handleCopy}>
              <Box sx={{ color: "text.primary" }} fontSize={0}>
                <Copy size={26} />
              </Box>
            </IconButton>

            <IconButton sx={{ p: 0 }} onClick={handleShare}>
              <Box sx={{ color: "text.primary" }} fontSize={0}>
                <ShareNetwork size={26} />
              </Box>
            </IconButton>

            <IconButton sx={{ p: 0 }} onClick={handleDelete}>
              <Box sx={{ color: "error.main" }} fontSize={0}>
                <TrashSimple size={26} />
              </Box>
            </IconButton>
          </Stack>
        </Stack>
      </Card>

      <Box height={bottomButtonHeight} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
        }}
      >
        <PrimaryButton
          isLoading={isLoadingButton}
          endIcon={<ArrowCounterClockwise weight="bold" size={20} />}
          onClick={handleReGenerate}
        >
          ایجاد دوباره
        </PrimaryButton>
      </Box>
    </Container>
  );
};

export default LetterWriterSingle;
