import axios from "axios";
import { apiUrl } from "../config/constants";
import { store } from "../config/store";
import { showAppSnackbar } from "../features/general/snackbarSlice";

export class ApiService {
  static axiosInstance = axios.create({
    baseURL: apiUrl,
  });

  static call = ({ showErrorMessage = true, ...config }) => {
    const token = window.localStorage.getItem("accessToken");

    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token ? `bearer ${token}` : "",
        Accept: "application/json",
      },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request(config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.message !== "canceled") {
            if (err.response?.status === 426) {
              window.CreditExhaustedChannel?.postMessage("CreditExhausted");
            }

            reject(err);

            if (showErrorMessage) {
              store.dispatch(
                showAppSnackbar({
                  severity: "error",
                  text:
                    err.message === "Network Error" &&
                    "navigator" in window &&
                    !navigator.onLine
                      ? "ارتباط با اینترنت برقرار نیست!"
                      : err.response?.data?.message ||
                        "مشکلی در ارتباط با سرور رخ داده است!",
                })
              );
            }
          }
        });
    });
  };
}

export default ApiService;
