import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowCounterClockwise, Check } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchLanguageExaminer,
  submitAnswersLanguageExaminer,
  updateUserAnswers,
} from "../../features/language_examiner/languageExaminerSlice";
import { routes } from "../../config/routes";
import SecondaryButton from "../../components/General/SecondaryButton";
import PrimaryButton from "../../components/General/PrimaryButton";

const AnswerItem = ({ step, label, selected, onClick, dir = "rtl" }) => {
  return (
    <Card
      component={"div"}
      onClick={onClick}
      sx={
        selected
          ? { p: 1.5, border: "1.4px solid", borderColor: "primary.main" }
          : { p: 1.5 }
      }
    >
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
          {step}
        </Typography>

        <Typography>{label}</Typography>

        {selected ? (
          <Box
            sx={{
              minWidth: 18,
              minHeight: 18,
              bgcolor: "primary.main",
              borderRadius: "100%",
              ml: dir === "rtl" ? "auto" : null,
              mr: dir === "ltr" ? "auto" : null,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Check weight="bold" size={14} />
          </Box>
        ) : (
          <Box
            sx={{
              minWidth: 18,
              minHeight: 18,
              bgcolor: "divider",
              borderRadius: "100%",
              ml: dir === "rtl" ? "auto" : null,
              mr: dir === "ltr" ? "auto" : null,
            }}
          />
        )}
      </Stack>
    </Card>
  );
};

const LanguageExaminerQuestions = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "آزمون زبان", hasBack: false })
    );
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [questionsCount, setQuestionsCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const bottomButtonRef = useRef(null);

  useEffect(() => {
    if (!isLoadingPage) {
      setBottomButtonHeight(
        bottomButtonRef.current?.getBoundingClientRect().height
      );
    }
  }, [isLoadingPage]);

  const { id: singleId } = useParams();
  const { questionId } = useParams();

  const currentLanguageExaminer = useSelector(
    (state) => state.languageExaminer.current
  );

  useEffect(() => {
    if (currentLanguageExaminer?.id !== singleId) {
      dispatch(fetchLanguageExaminer({ id: singleId })).then((res) => {
        if (res.error !== undefined) {
          navigate(routes.root);
        } else {
          setIsLoadingPage(false);
        }
      });
    } else {
      setIsLoadingPage(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoadingPage) {
      setQuestionsCount(currentLanguageExaminer.questionsCount);
      setQuestions(currentLanguageExaminer.questions);
      if (
        currentLanguageExaminer.questions.length > 0 &&
        !currentLanguageExaminer.questions[parseInt(questionId) - 1]
      ) {
        navigate(
          routes.languageExaminerQuestions
            .replace(":id", singleId)
            .replace(":questionId", 1)
        );
      }
    }
  }, [
    currentLanguageExaminer.questions,
    currentLanguageExaminer.questionsCount,
    isLoadingPage,
    navigate,
    questionId,
    singleId,
  ]);

  useEffect(() => {
    setSelectedOption(
      currentLanguageExaminer.userAnswers[parseInt(questionId) - 1] ?? null
    );
  }, [currentLanguageExaminer.userAnswers, navigate, questionId]);

  const handleSelectOption = (option) => {
    setSelectedOption(option);

    dispatch(
      updateUserAnswers({ index: parseInt(questionId) - 1, option: option })
    );
  };

  const handleNext = () => {
    if (selectedOption === null) {
      dispatch(
        updateUserAnswers({ index: parseInt(questionId) - 1, option: null })
      );
    }

    navigate(
      routes.languageExaminerQuestions
        .replace(":id", singleId)
        .replace(":questionId", parseInt(questionId) + 1)
    );
  };

  const handlePrev = () => {
    navigate(
      routes.languageExaminerQuestions
        .replace(":id", singleId)
        .replace(":questionId", parseInt(questionId) - 1)
    );
  };

  const handleSubmit = () => {
    const userAnswers = Array.from(currentLanguageExaminer.userAnswers);

    if (selectedOption === null) {
      userAnswers.push(null);
    }

    setIsLoadingButton(true);

    dispatch(
      submitAnswersLanguageExaminer({
        id: singleId,
        answers: userAnswers,
      })
    ).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        navigate(routes.languageExaminerSingle.replace(":id", singleId), {
          replace: true,
        });
      }
    });
  };

  return isLoadingPage ? (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack
        width={"100%"}
        height={`calc(100vh - 66px)`}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={26} />
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Box>
        <Typography variant="body2" mb={1.5}>
          سوال {questionId} از {questionsCount}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          mb={3}
          dir={currentLanguageExaminer?.questionsLanguage === 2 ? "ltr" : "rtl"}
        >
          {questions[parseInt(questionId) - 1]?.question ?? ""}
        </Typography>

        <Stack
          dir={currentLanguageExaminer?.questionsLanguage === 2 ? "ltr" : "rtl"}
          gap={1.5}
        >
          <AnswerItem
            step={"A"}
            label={
              questions[parseInt(questionId) - 1]?.options[0]?.content ?? ""
            }
            onClick={() => handleSelectOption(1)}
            selected={selectedOption === 1}
            dir={
              currentLanguageExaminer?.questionsLanguage === 2 ? "ltr" : "rtl"
            }
          />

          <AnswerItem
            step={"B"}
            label={
              questions[parseInt(questionId) - 1]?.options[1]?.content ?? ""
            }
            onClick={() => handleSelectOption(2)}
            selected={selectedOption === 2}
            dir={
              currentLanguageExaminer?.questionsLanguage === 2 ? "ltr" : "rtl"
            }
          />

          <AnswerItem
            step={"C"}
            label={
              questions[parseInt(questionId) - 1]?.options[2]?.content ?? ""
            }
            onClick={() => handleSelectOption(3)}
            selected={selectedOption === 3}
            dir={
              currentLanguageExaminer?.questionsLanguage === 2 ? "ltr" : "rtl"
            }
          />

          <AnswerItem
            step={"D"}
            label={
              questions[parseInt(questionId) - 1]?.options[3]?.content ?? ""
            }
            onClick={() => handleSelectOption(4)}
            selected={selectedOption === 4}
            dir={
              currentLanguageExaminer?.questionsLanguage === 2 ? "ltr" : "rtl"
            }
          />
        </Stack>
      </Box>

      <Box height={bottomButtonHeight} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {parseInt(questionId) > 1 && (
              <SecondaryButton onClick={handlePrev}>سوال قبلی</SecondaryButton>
            )}
          </Grid>

          <Grid item xs={6}>
            {parseInt(questionId) < questionsCount ? (
              <PrimaryButton onClick={handleNext}>سوال بعدی</PrimaryButton>
            ) : parseInt(questionId) === questionsCount ? (
              <PrimaryButton isLoading={isLoadingButton} onClick={handleSubmit}>
                ثبت پاسخ‌ها
              </PrimaryButton>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default LanguageExaminerQuestions;
