import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowLeft,
  ClockCounterClockwise,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import { generateLetterWriter } from "../../features/letter_writer/letterWriterSlice";
import PrimaryButton from "../../components/General/PrimaryButton";

const GenerateLetterWriter = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "نامه نویس", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const [type, setType] = useState("1");
  const [length, setLength] = useState("1");
  const [tone, setTone] = useState("1");
  const [subject, setSubject] = useState("");
  const [repliedMessage, setRepliedMessage] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [toolPrice, setToolPrice] = useState("0");

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);

  const bottomButtonRef = useRef(null);

  const dispatch = useDispatch();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  const currentLetterWriter = useSelector(
    (state) => state.letterWriter.current
  );

  useEffect(() => {
    if (toolsPrices) {
      const basePrice = toolsPrices?.letterWriter?.base ?? 0;
      const typePrice = toolsPrices?.letterWriter?.extra?.type[`${type}`] ?? 0;
      const lengthPrice =
        toolsPrices?.letterWriter?.extra?.length[`${length}`] ?? 0;

      setToolPrice(basePrice + typePrice + lengthPrice);
    }
  }, [length, toolsPrices, type]);

  useEffect(() => {
    setBottomButtonHeight(
      bottomButtonRef.current?.getBoundingClientRect().height
    );
  }, []);

  useEffect(() => {
    if (submitted && currentLetterWriter?.id) {
      navigate(
        routes.letterWriterSingle?.replace(":id", currentLetterWriter?.id),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentLetterWriter?.id, navigate, submitted]);

  const handleGenerate = () => {
    const subjectValue = subject?.trim();
    const repliedMessageValue = repliedMessage?.trim();

    if (repliedMessageValue === "" && subjectValue === "") {
      dispatch(
        showAppSnackbar({
          severity: "error",
          text: "لطفا موضوع  را وارد کنید.",
        })
      );
      return;
    }

    setIsLoadingButton(true);

    dispatch(
      generateLetterWriter({
        type: type,
        length: length,
        tone: tone,
        subject: subjectValue,
        repliedMessage: repliedMessageValue,
      })
    ).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  return (
    <Container maxWidth={"xs"} sx={{ p: 2, position: "relative" }}>
      <Stack gap={2}>
        <Box>
          <Typography fontWeight={600} mb={1.5}>
            نوع نامه
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              جدید
            </MenuItem>

            <MenuItem key={2} value="2">
              در پاسخ به پیام
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            لحن
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={tone}
            onChange={(e) => setTone(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              رسمی
            </MenuItem>

            <MenuItem key={2} value="2">
              غیررسمی
            </MenuItem>

            <MenuItem key={3} value="3">
              خوش‌بین
            </MenuItem>

            <MenuItem key={4} value="4">
              نگران
            </MenuItem>

            <MenuItem key={5} value="5">
              دوستانه
            </MenuItem>

            <MenuItem key={6} value="6">
              کنجکاو
            </MenuItem>

            <MenuItem key={7} value="7">
              قاطع
            </MenuItem>

            <MenuItem key={8} value="8">
              تشویق‌آمیز
            </MenuItem>

            <MenuItem key={9} value="9">
              متعجب
            </MenuItem>

            <MenuItem key={10} value="10">
              همکارانه
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            طول
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={length}
            onChange={(e) => setLength(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              کوتاه
            </MenuItem>

            <MenuItem key={2} value="2">
              متوسط
            </MenuItem>

            <MenuItem key={3} value="3">
              بلند
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            موضوع
          </Typography>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                bgcolor: "background.paper",
              },
            }}
            type="text"
            multiline
            rows={3}
            variant="outlined"
            placeholder="در چه مورد و برای چه کسی مینویسید؟"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Box>

        {type === "2" && (
          <Box>
            <Typography fontWeight={600} mb={1.5}>
              در پاسخ به پیام
            </Typography>

            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  bgcolor: "background.paper",
                },
              }}
              type="text"
              multiline
              rows={3}
              variant="outlined"
              placeholder="متنی که میخواهید برای آن پاسخی در جواب شخص مورد نظر بنویسید..."
              fullWidth
              value={repliedMessage}
              onChange={(e) => setRepliedMessage(e.target.value)}
            />
          </Box>
        )}
      </Stack>

      <Box sx={{ height: bottomButtonHeight }} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
          bgcolor: "background.default",
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} gap={0.5} mb={2}>
          <Box sx={{ color: "primary.main" }} fontSize={0}>
            <CurrencyCircleDollar size={18} />
          </Box>

          <Typography variant="body2" fontSize={13} textAlign={"center"}>
            هزینه ایجاد نامه: <b>{toolPrice}</b> اعتبار
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Link to={routes.letterWriterHistory}>
            <Box sx={{ color: "text.primary" }} fontSize={0}>
              <ClockCounterClockwise size={28} />
            </Box>
          </Link>

          <PrimaryButton
            isLoading={isLoadingButton}
            endIcon={<ArrowLeft weight="bold" size={20} />}
            onClick={handleGenerate}
          >
            ایجاد نامه
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default GenerateLetterWriter;
