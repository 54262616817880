import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  title: null,
  acceptText: null,
  rejectText: null,
  acceptCallback: null,
};

export const showAppDialog = createAsyncThunk(
  "dialog/show",
  async (payload, thunkApi) => {
    await thunkApi.dispatch(cleanupAppDialog());
    return thunkApi.fulfillWithValue(payload);
  }
);

export const hideAppDialog = createAction("dialog/hide");

export const cleanupAppDialog = createAsyncThunk("dialog/cleanup");

const dialogSlice = createSlice({
  initialState,
  name: "dialog",
  extraReducers: (builder) => {
    builder.addCase(showAppDialog.fulfilled, (state, action) => {
      state.open = true;
      state.title = action.payload?.title ?? null;
      state.acceptText = action.payload?.acceptText ?? null;
      state.rejectText = action.payload?.rejectText ?? null;
      state.acceptCallback = action.payload?.acceptCallback ?? null;
    });

    builder.addCase(hideAppDialog, (state) => {
      state.open = false;
    });

    builder.addCase(cleanupAppDialog.fulfilled, (state) => {
      state.open = initialState.open;
      state.title = initialState.title;
      state.acceptText = initialState.acceptText;
      state.rejectText = initialState.rejectText;
      state.acceptCallback = initialState.acceptCallback;
    });
  },
});

export default dialogSlice.reducer;
