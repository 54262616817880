export const isEqualTwoObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const firstCapitalLetters = (text) => {
  const words = text.split(/\s+/).slice(0, 2);

  const initials = words.map((word) => word.charAt(0).toUpperCase());

  const result = initials.join("‌");

  return result;
};

export const mockApiResponse = (response, seconds = 2) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, seconds * 1000);
  });
};

export const formatFileSize = (fileSizeInBytes) => {
  if (fileSizeInBytes < 1024) {
    return fileSizeInBytes + " bytes";
  } else if (fileSizeInBytes < 1024 * 1024) {
    return (fileSizeInBytes / 1024).toFixed(2) + "KB";
  } else {
    return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + "MB";
  }
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formDataCreate = (obj) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(obj)) {
    if (value && value !== "") {
      formData.append(key, value);
    }
  }

  return formData;
};

export const isLocalEnv = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export function numberWithCommas(num) {
  var roundedNumber = Math.round(num * 100) / 100;
  var parts = roundedNumber.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
