import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  history: [],
  current: null,
};

export const generatePredictor = createAsyncThunk(
  "predictor/generatePredictor",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/predictor/requests`,
        data: {
          type: payload.type,
          inputMeta: {
            userName: payload?.userName?.length > 0 ? payload?.userName : null,
            userPartnerName:
              payload?.userPartnerName?.length > 0
                ? payload?.userPartnerName
                : null,
            birthDate:
              payload?.birthDate?.length > 0 ? payload?.birthDate : null,
          },
        },
      });

      thunkApi.dispatch(saveCurrentPredictor(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reGeneratePredictor = createAsyncThunk(
  "predictor/reGeneratePredictor",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/predictor/requests/${payload.id}/regenerate`,
      });

      thunkApi.dispatch(saveCurrentPredictor(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllPredictorHistory = createAsyncThunk(
  "predictor/fetchAllPredictorHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/predictor/requests?page=${payload.page}&limit=${payload.limit}`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPredictor = createAsyncThunk(
  "predictor/fetchPredictor",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/predictor/requests/${payload.id}`,
      });

      thunkApi.dispatch(saveCurrentPredictor(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deletePredictor = createAsyncThunk(
  "predictor/deletePredictor",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/predictor/requests`,
        data: { ids: [payload.id] },
      });

      thunkApi.dispatch(cleanupCurrentPredictor());

      return payload.id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearAllPredictorHistory = createAsyncThunk(
  "predictor/clearAllPredictorHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/predictor/requests`,
        data: { ids: null },
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCurrentPredictor = createAction(
  "predictor/saveCurrentPredictor"
);

export const cleanupCurrentPredictor = createAction(
  "predictor/cleanupCurrentPredictor"
);

const predictorSlice = createSlice({
  initialState,
  name: "predictor",
  extraReducers: (builder) => {
    builder.addCase(saveCurrentPredictor, (state, action) => {
      state.current = action.payload;
    });

    builder.addCase(cleanupCurrentPredictor, (state) => {
      state.current = initialState.current;
    });

    builder.addCase(fetchAllPredictorHistory.fulfilled, (state, action) => {
      state.history = action.payload?.rows;
    });

    builder.addCase(clearAllPredictorHistory.fulfilled, (state) => {
      state.history = [];
    });

    builder.addCase(deletePredictor.fulfilled, (state, action) => {
      state.history = state.history.filter((item) => item?.id !== action);
    });
  },
});

export default predictorSlice.reducer;
