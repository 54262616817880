import { Card, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { numberWithCommas } from "../../services/helpers";
import moment from "moment-jalaali";
import { cleanupGoldAndCurrencyPricesList } from "../../features/currency_price_informer/currencyPriceInformerSlice";

const GoldListResult = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "آخرین قیمت‌های ارز، طلا و سکه", hasBack: true })
    );
  }, []);

  const goldList = useSelector((state) => state.currenyPriceInformer.goldList);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (goldList.length === 0) {
      navigate(routes.root);
    }

    return () => dispatch(cleanupGoldAndCurrencyPricesList());
  }, []);

  return (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack gap={2}>
        {goldList.map((item, i) => (
          <Card sx={{ p: 2 }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              gap={2}
              mb={1.5}
            >
              <Typography variant="body2" fontWeight={700}>
                {item?.title}
              </Typography>

              <Typography variant="body2">
                {numberWithCommas(item?.price / 10 ?? 0)} تومان
              </Typography>
            </Stack>

            <Typography variant="body2" fontWeight={300} fontSize={13}>
              آخرین بروزرسانی:{" "}
              {item?.lastUpdated?.length > 0 &&
                moment(item?.lastUpdated).format("jYYYY/jM/jD - HH:mm")}
            </Typography>
          </Card>
        ))}
      </Stack>
    </Container>
  );
};

export default GoldListResult;
