import { Box, Stack } from "@mui/material";
import Markdown from "markdown-to-jsx";

const MarkdownFormat = ({ sx = {}, gap = 2, children }) => {
  return (
    <Box
      sx={(theme) => ({
        "& div": {
          gap: gap,
          display: "flex",
          flexDirection: "column",
          whiteSpace: "pre-wrap",
        },
        "& h3": {
          fontSize: theme.typography.h3,
        },
        "& h2": {
          fontSize: theme.typography.h2,
        },
        "& h1": {
          fontSize: theme.typography.h1,
        },
        "& p, & span, & li": {
          fontSize: theme.typography.body2,
        },
        "& *": {
          margin: 0,
        },
        ...sx,
      })}
    >
      <Markdown>{children}</Markdown>
    </Box>
  );
};

export default MarkdownFormat;
