import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  text: null,
  time: 3000,
  severity: "error",
  open: false,
};

export const showAppSnackbar = createAsyncThunk(
  "snackbar/show",
  async (payload, thunkApi) => {
    await thunkApi.dispatch(cleanupAppSnackbar());
    return thunkApi.fulfillWithValue(payload);
  }
);
export const cleanupAppSnackbar = createAsyncThunk("snackbar/cleanup");

export const hideAppSnackbar = createAction("snackbar/hide");

const snackbarSlice = createSlice({
  initialState,
  name: "snackbar",
  extraReducers: (builder) => {
    builder.addCase(showAppSnackbar.fulfilled, (state, action) => {
      if (action.payload?.severity !== undefined) {
        state.severity = action.payload.severity;
      }
      if (action.payload?.time !== undefined) {
        state.time = action.payload.time;
      }
      if (action.payload?.text !== undefined) {
        state.text = action.payload.text;
      }

      state.open = true;
    });

    builder.addCase(hideAppSnackbar, (state) => {
      state.open = false;
    });

    builder.addCase(cleanupAppSnackbar.fulfilled, (state) => {
      state.open = initialState.open;
      state.severity = initialState.severity;
      state.text = initialState.text;
      state.time = initialState.time;
    });
  },
});

export default snackbarSlice.reducer;
