import {
  Box,
  Card,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowCounterClockwise, TrashSimple } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../config/routes";
import {
  deleteMovieSuggester,
  fetchMovieSuggester,
  reGenerateMovieSuggester,
} from "../../features/movie_suggester/movieSuggesterSlice";
import {
  hideAppDialog,
  showAppDialog,
} from "../../features/general/dialogSlice";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import moment from "moment-jalaali";
import PrimaryButton from "../../components/General/PrimaryButton";
import SecondaryButton from "../../components/General/SecondaryButton";

const MovieSuggesterSingle = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "پیشنهاد فیلم", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const { id: singleId } = useParams();

  const currentMovieSuggester = useSelector(
    (state) => state.movieSuggester.current
  );

  const bottomButtonRef = useRef(null);

  useEffect(() => {
    if (!isLoadingPage) {
      setBottomButtonHeight(
        bottomButtonRef.current?.getBoundingClientRect().height
      );
    }
  }, [isLoadingPage]);

  useEffect(() => {
    if (submitted && currentMovieSuggester?.id) {
      navigate(
        routes.movieSuggesterSingle?.replace(":id", currentMovieSuggester?.id),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentMovieSuggester?.id, navigate, submitted]);

  useEffect(() => {
    if (currentMovieSuggester?.id !== singleId) {
      dispatch(fetchMovieSuggester({ id: singleId })).then((res) => {
        if (res.error !== undefined) {
          navigate(routes.root);
        } else {
          setIsLoadingPage(false);
        }
      });
    } else {
      setIsLoadingPage(false);
    }
  }, []);

  const handleReadMore = (title, releasedAt, type) => {
    const url = encodeURI(
      `https://www.google.com/search?q=${
        type === 1 ? "فیلم سینمایی" : type === 2 ? "سریال" : "فیلم"
      } ${title} ${releasedAt}`
    );

    window.OpenBrowserChannel?.postMessage(
      JSON.stringify({
        url: url,
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      showAppDialog({
        title: "آیا از حذف این مورد اطمینان دارید؟",
        acceptCallback: () => {
          dispatch(
            deleteMovieSuggester({ id: currentMovieSuggester?.id })
          ).then((res) => {
            if (res.error === undefined) {
              dispatch(
                showAppSnackbar({
                  severity: "success",
                  text: "با موفقیت حذف شد.",
                })
              );

              navigate(routes.root, { replace: true });
            }
          });

          dispatch(hideAppDialog());
        },
      })
    );
  };

  const handleReGenerate = () => {
    dispatch(
      showAppDialog({
        title: "آیا از ایجاد دوباره این مورد اطمینان دارید؟",
        acceptCallback: () => {
          setIsLoadingButton(true);

          dispatch(
            reGenerateMovieSuggester({ id: currentMovieSuggester?.id })
          ).then((res) => {
            setIsLoadingButton(false);

            if (res.error === undefined) {
              setSubmitted(true);

              window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
            }
          });

          dispatch(hideAppDialog());
        },
      })
    );
  };

  return isLoadingPage ? (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack
        width={"100%"}
        height={`calc(100vh - 66px)`}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={26} />
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Card sx={{ p: 2 }}>
        <Stack gap={2}>
          <Stack gap={2}>
            {currentMovieSuggester?.result?.map((item, i) => (
              <Stack gap={1.5}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    عنوان:
                  </Typography>

                  <Typography variant="body2">{item?.title ?? "-"}</Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    نوع:
                  </Typography>

                  <Typography variant="body2">
                    {item?.type === 1
                      ? "سینمایی"
                      : item?.type === 2
                      ? "سریال"
                      : "-"}
                  </Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    ژانر:
                  </Typography>

                  <Typography variant="body2">{item?.genre ?? "-"}</Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    سال ساخت:
                  </Typography>

                  <Typography variant="body2">
                    {item?.releasedAt ?? "-"}
                  </Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    کشور سازنده:
                  </Typography>

                  <Typography variant="body2">
                    {item?.country ?? "-"}
                  </Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    مدت زمان (دقیقه):
                  </Typography>

                  <Typography variant="body2">
                    {item?.duration ?? "-"}
                  </Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    کارگردان:
                  </Typography>

                  <Typography variant="body2">
                    {item?.director ?? "-"}
                  </Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    بازیگران:
                  </Typography>

                  <Typography variant="body2">{item?.actors ?? "-"}</Typography>
                </Stack>

                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="body2" fontWeight={700}>
                    خلاصه:
                  </Typography>

                  <Typography variant="body2">{item?.plot ?? "-"}</Typography>
                </Stack>

                <SecondaryButton
                  size="small"
                  onClick={() =>
                    handleReadMore(item?.title, item?.releasedAt, item?.type)
                  }
                >
                  مشاهده بیشتر
                </SecondaryButton>

                <Box
                  sx={{
                    borderBottom: "1.6px dashed",
                    height: 1,
                    borderColor: "divider",
                  }}
                />
              </Stack>
            ))}
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={2}
          >
            <Typography variant="body2" fontWeight={300} mr={"auto"}>
              {currentMovieSuggester?.createdAt?.length > 0 &&
                moment(currentMovieSuggester?.createdAt).format(
                  "jYYYY/jM/jD - HH:mm"
                )}
            </Typography>

            <IconButton sx={{ p: 0 }} onClick={handleDelete}>
              <Box sx={{ color: "error.main" }} fontSize={0}>
                <TrashSimple size={26} />
              </Box>
            </IconButton>
          </Stack>
        </Stack>
      </Card>

      <Box height={bottomButtonHeight} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
        }}
      >
        <PrimaryButton
          isLoading={isLoadingButton}
          endIcon={<ArrowCounterClockwise weight="bold" size={20} />}
          onClick={handleReGenerate}
        >
          ایجاد دوباره
        </PrimaryButton>
      </Box>
    </Container>
  );
};

export default MovieSuggesterSingle;
