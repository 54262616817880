import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  data: null,
};

export const fetchToolsPrices = createAsyncThunk(
  "toolsPrices/fetchToolsPrices",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/setting?currentVersion=2.2.0`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const toolsPricesSlice = createSlice({
  initialState,
  name: "toolsPrices",
  extraReducers: (builder) => {
    builder.addCase(fetchToolsPrices.fulfilled, (state, action) => {
      state.data = action.payload?.toolsPrices;
    });
  },
});

export default toolsPricesSlice.reducer;
