import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchToolsPrices } from "./features/settings/toolsPricesSlice";

const AppProvider = ({ children }) => {
  const dispatch = useDispatch();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  useEffect(() => {
    if (toolsPrices === null) {
      dispatch(fetchToolsPrices());
    }
  }, [dispatch, toolsPrices]);

  return children;
};

export default AppProvider;
