import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowCounterClockwise } from "@phosphor-icons/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../config/routes";
import {
  fetchLanguageExaminer,
  reGenerateLanguageExaminer,
} from "../../features/language_examiner/languageExaminerSlice";
import {
  hideAppDialog,
  showAppDialog,
} from "../../features/general/dialogSlice";
import PrimaryButton from "../../components/General/PrimaryButton";

const LanguageExaminerSingle = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "آزمون زبان", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [trueAnswers, setTrueAnswers] = useState(0);
  const [falseAnswers, setFalseAnswers] = useState(0);
  const [notAnswers, setNotAnswers] = useState(0);

  const { id: singleId } = useParams();

  const currentLanguageExaminer = useSelector(
    (state) => state.languageExaminer.current
  );

  const bottomButtonRef = useRef(null);

  useEffect(() => {
    if (!isLoadingPage) {
      setBottomButtonHeight(
        bottomButtonRef.current?.getBoundingClientRect().height
      );
    }
  }, [isLoadingPage]);

  useEffect(() => {
    if (submitted && currentLanguageExaminer?.id) {
      navigate(
        routes.languageExaminerQuestions
          ?.replace(":id", currentLanguageExaminer?.id)
          .replace(":questionId", 1),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentLanguageExaminer?.id, navigate, submitted]);

  useEffect(() => {
    if (currentLanguageExaminer?.id !== singleId) {
      dispatch(fetchLanguageExaminer({ id: singleId })).then((res) => {
        if (res.error !== undefined) {
          navigate(routes.root);
        } else {
          setIsLoadingPage(false);
        }
      });
    } else {
      setIsLoadingPage(false);
    }
  }, []);

  useEffect(() => {
    if (
      !isLoadingPage &&
      currentLanguageExaminer.answerSheet.length === 0 &&
      currentLanguageExaminer.userAnswers.length === 0
    ) {
      navigate(routes.root);
    } else if (!isLoadingPage) {
      currentLanguageExaminer.userAnswers.forEach((userAnswer, index) => {
        const correctAnswer = currentLanguageExaminer.answerSheet[index]?.id;
        if (userAnswer === null) {
          setNotAnswers((lastState) => ++lastState);
        } else if (userAnswer === correctAnswer) {
          setTrueAnswers((lastState) => ++lastState);
        } else {
          setFalseAnswers((lastState) => ++lastState);
        }
      });
    }
  }, [isLoadingPage]);

  const handleReGenerate = () => {
    dispatch(
      showAppDialog({
        title: "آیا از ایجاد دوباره این آزمون اطمینان دارید؟",
        acceptCallback: () => {
          setIsLoadingButton(true);

          dispatch(
            reGenerateLanguageExaminer({ id: currentLanguageExaminer?.id })
          ).then((res) => {
            setIsLoadingButton(false);

            if (res.error === undefined) {
              setSubmitted(true);

              window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
            }
          });

          dispatch(hideAppDialog());
        },
      })
    );
  };

  return isLoadingPage ? (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack
        width={"100%"}
        height={`calc(100vh - 66px)`}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={26} />
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" sx={{ p: 2 }}>
      <Stack gap={4}>
        <Stack gap={1.5}>
          <Typography variant="subtitle2">نتیجه آزمون شما:</Typography>

          <Card sx={{ p: 2 }}>
            <Stack gap={1.5}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={0.75}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "100%",
                    bgcolor: "success.main",
                  }}
                />
                <Typography variant="body2">پاسخ صحیح:</Typography>
                <Box
                  sx={{
                    flex: 1,
                    borderBottom: "1.6px dashed",
                    height: 1,
                    borderColor: "divider",
                  }}
                />
                <Typography variant="body2">
                  {trueAnswers}/{currentLanguageExaminer.questionsCount}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={0.75}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "100%",
                    bgcolor: "error.main",
                  }}
                />
                <Typography variant="body2">پاسخ غلط:</Typography>
                <Box
                  sx={{
                    flex: 1,
                    borderBottom: "1.6px dashed",
                    height: 1,
                    borderColor: "divider",
                  }}
                />
                <Typography variant="body2">
                  {falseAnswers}/{currentLanguageExaminer.questionsCount}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={0.75}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "100%",
                    bgcolor: "grey.300",
                  }}
                />
                <Typography variant="body2">بدون پاسخ:</Typography>
                <Box
                  sx={{
                    flex: 1,
                    borderBottom: "1.6px dashed",
                    height: 1,
                    borderColor: "divider",
                  }}
                />
                <Typography variant="body2">
                  {notAnswers}/{currentLanguageExaminer.questionsCount}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Stack>

        <Stack gap={1.5}>
          <Typography variant="subtitle2">پاسخنامه:</Typography>

          <Card sx={{ p: 2 }}>
            <Stack gap={3}>
              {currentLanguageExaminer?.questions.map((question, i) => (
                <>
                  {i !== 0 && (
                    <Box
                      key={i * 2}
                      sx={{
                        width: "100%",
                        borderBottom: "1.6px dashed",
                        height: 1,
                        borderColor: "divider",
                      }}
                    />
                  )}

                  <Stack key={i} gap={1}>
                    <Typography variant="body2">
                      سوال {i + 1} از {currentLanguageExaminer.questionsCount}
                    </Typography>
                    <Typography
                      dir={
                        currentLanguageExaminer?.questionsLanguage === 2
                          ? "ltr"
                          : "rtl"
                      }
                      variant="body1"
                      fontWeight={500}
                    >
                      {question?.question}
                    </Typography>
                    <Stack
                      dir={
                        currentLanguageExaminer?.questionsLanguage === 2
                          ? "ltr"
                          : "rtl"
                      }
                      gap={1.5}
                      my={1}
                    >
                      <Stack direction={"row"} alignItems={"center"} gap={0.75}>
                        <Box
                          sx={{
                            borderRadius: 1,
                            bgcolor:
                              currentLanguageExaminer?.answerSheet[i]?.id === 1
                                ? "success.main"
                                : currentLanguageExaminer?.userAnswers[i] !==
                                    currentLanguageExaminer?.answerSheet[i]
                                      ?.id &&
                                  currentLanguageExaminer?.userAnswers[i] === 1
                                ? "error.main"
                                : "divider",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: 30,
                            minHeight: 30,
                            fontWeight: 600,
                          }}
                        >
                          A
                        </Box>

                        <Typography variant="body1">
                          {question?.options[0]?.content ?? ""}
                        </Typography>
                      </Stack>

                      <Stack direction={"row"} alignItems={"center"} gap={0.75}>
                        <Box
                          sx={{
                            borderRadius: 1,
                            bgcolor:
                              currentLanguageExaminer?.answerSheet[i]?.id === 2
                                ? "success.main"
                                : currentLanguageExaminer?.userAnswers[i] !==
                                    currentLanguageExaminer?.answerSheet[i]
                                      ?.id &&
                                  currentLanguageExaminer?.userAnswers[i] === 2
                                ? "error.main"
                                : "divider",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: 30,
                            minHeight: 30,
                            fontWeight: 600,
                          }}
                        >
                          B
                        </Box>

                        <Typography variant="body1">
                          {question?.options[1]?.content ?? ""}
                        </Typography>
                      </Stack>

                      <Stack direction={"row"} alignItems={"center"} gap={0.75}>
                        <Box
                          sx={{
                            borderRadius: 1,
                            bgcolor:
                              currentLanguageExaminer?.answerSheet[i]?.id === 3
                                ? "success.main"
                                : currentLanguageExaminer?.userAnswers[i] !==
                                    currentLanguageExaminer?.answerSheet[i]
                                      ?.id &&
                                  currentLanguageExaminer?.userAnswers[i] === 3
                                ? "error.main"
                                : "divider",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: 30,
                            minHeight: 30,
                            fontWeight: 600,
                          }}
                        >
                          C
                        </Box>

                        <Typography variant="body1">
                          {question?.options[2]?.content ?? ""}
                        </Typography>
                      </Stack>

                      <Stack direction={"row"} alignItems={"center"} gap={0.75}>
                        <Box
                          sx={{
                            borderRadius: 1,
                            bgcolor:
                              currentLanguageExaminer?.answerSheet[i]?.id === 4
                                ? "success.main"
                                : currentLanguageExaminer?.userAnswers[i] !==
                                    currentLanguageExaminer?.answerSheet[i]
                                      ?.id &&
                                  currentLanguageExaminer?.userAnswers[i] === 4
                                ? "error.main"
                                : "divider",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: 30,
                            minHeight: 30,
                            fontWeight: 600,
                          }}
                        >
                          D
                        </Box>

                        <Typography variant="body1">
                          {question?.options[3]?.content ?? ""}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="body2">
                      {currentLanguageExaminer?.answerSheet[i]?.reason}
                    </Typography>
                  </Stack>
                </>
              ))}
            </Stack>
          </Card>
        </Stack>
      </Stack>

      <Box height={bottomButtonHeight} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
        }}
      >
        <PrimaryButton
          isLoading={isLoadingButton}
          endIcon={<ArrowCounterClockwise weight="bold" size={20} />}
          onClick={handleReGenerate}
        >
          آزمون مجدد
        </PrimaryButton>
      </Box>
    </Container>
  );
};

export default LanguageExaminerSingle;
