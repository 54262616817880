import createCache from "@emotion/cache";
import { createTheme } from "@mui/material";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";

export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, stylisRTLPlugin],
});

const baseTheme = createTheme({
  direction: "rtl",
  palette: {
    success: {
      main: "#22D86B",
    },
    error: {
      main: "#FF000F"
    }
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: [
      "IranYekan",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: 32,
      fontWeight: 800,
    },
    h2: {
      fontSize: 18,
      fontWeight: 800,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
    },
    body2: {
      fontSize: 15,
    },
    body1: {
      fontSize: 17,
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 200;
                      src: url(/assets/fonts/iranyekanwebthinfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 300;
                      src: url(/assets/fonts/iranyekanweblightfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 400;
                      src: url(/assets/fonts/iranyekanwebregularfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 500;
                      src: url(/assets/fonts/iranyekanwebmediumfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 600;
                      src: url(/assets/fonts/iranyekanwebboldfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 700;
                      src: url(/assets/fonts/iranyekanwebextraboldfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 800;
                      src: url(/assets/fonts/iranyekanwebblackfanum.woff) format('woff');
                  }
                  @font-face {
                      font-family: 'IranYekan';
                      font-style: normal;
                      font-display: swap;
                      font-weight: 900;
                      src: url(/assets/fonts/iranyekanwebextrablackfanum.woff) format('woff');
                  }
                  a{
                      text-decoration: none !important;
                  }
              `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: 15,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          fontSize: 15,
        },
      },
    },
  },
});

export const lightTheme = createTheme(baseTheme, {
  palette: {
    mode: "light",
    primary: {
      main: "#FFC100",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#263238",
      contrastText: "#FFF",
    },
    background: {
      paper: "#FFF",
      default: "#F9FBFF",
    },
    error: {
      main: "#F44336",
    },
    grey: {
      100: "#F4F4F4",
    },
    divider: "#E8EBF2",
  },
  shadows: [
    "none",
    "0px 4px 15px -6px rgba(38, 50, 56, 0.3)",
    ...Array(23).fill("none"),
  ],
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "#ddd",
      },
      root: {
        borderColor: "#ddd",
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline, &:not(.Mui-focused):not(.Mui-error):hover .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#ddd",
          },
      },
    },
  },
});

export const darkTheme = createTheme(baseTheme, {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFC100",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#263238",
    },
    background: {
      paper: "#2F414A",
      default: "#263238",
    },
    error: {
      main: "#F44336",
    },
    grey: {
      100: "#F4F4F4",
    },
    divider: "#4a5a62",
    text: {
      primary: "#FFF",
      secondary: "#FFF",
    },
  },
  shadows: [
    "none",
    "0px 2px 15px -6px rgba(20, 27, 31, 0.545)",
    ...Array(23).fill("none"),
  ],
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "#ddd",
      },
      root: {
        borderColor: "#ddd",
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline, &:not(.Mui-focused):not(.Mui-error):hover .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#ddd",
          },
      },
    },
  },
});
