import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowLeft,
  ClockCounterClockwise,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import { generateMovieSuggester } from "../../features/movie_suggester/movieSuggesterSlice";
import PrimaryButton from "../../components/General/PrimaryButton";
import {
  movieSuggesterMovieGenre,
  movieSuggesterMovieGenreies,
  movieSuggesterMovieMakerCountries,
} from "../../config/constants";
import {
  getCryptoCurrencyPricesList,
  getGoldAndCurrencyPricesList,
} from "../../features/currency_price_informer/currencyPriceInformerSlice";

const GenerateCurrenyPriceInformer = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "قیمت‌های بازار", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const [type, setType] = useState("1");

  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [toolPrice, setToolPrice] = useState("0");

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);

  const bottomButtonRef = useRef(null);

  const dispatch = useDispatch();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  const goldList = useSelector((state) => state.currenyPriceInformer.goldList);

  const cryptoList = useSelector(
    (state) => state.currenyPriceInformer.cryptoList
  );

  useEffect(() => {
    if (toolsPrices) {
      const basePrice = toolsPrices?.currencyPriceInformer ?? 0;

      setToolPrice(basePrice);
    }
  }, [toolsPrices]);

  useEffect(() => {
    setBottomButtonHeight(
      bottomButtonRef.current?.getBoundingClientRect().height
    );
  }, []);

  useEffect(() => {
    if (submitted && type === "1" && goldList.length > 0) {
      navigate(routes.goldListResult);

      setSubmitted(false);
    }

    if (submitted && type === "2" && cryptoList.length > 0) {
      navigate(routes.cryptoListResult);

      setSubmitted(false);
    }
  }, [goldList, cryptoList, navigate, submitted, type]);

  const handleGetGoldList = () => {
    setIsLoadingButton(true);

    dispatch(getGoldAndCurrencyPricesList()).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  const handleGetCryptoList = () => {
    setIsLoadingButton(true);

    dispatch(getCryptoCurrencyPricesList()).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  return (
    <Container maxWidth={"xs"} sx={{ p: 2, position: "relative" }}>
      <Stack gap={2}>
        <Box>
          <Typography fontWeight={600} mb={1.5}>
            نوع بازار
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            fullWidth
          >
            <MenuItem key={1} value="1">
              ارز، طلا و سکه
            </MenuItem>

            <MenuItem key={2} value="2">
              ارز های دیجیتال
            </MenuItem>
          </Select>
        </Box>
      </Stack>

      <Box sx={{ height: bottomButtonHeight }} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
          bgcolor: "background.default",
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} gap={0.5} mb={2}>
          <Box sx={{ color: "primary.main" }} fontSize={0}>
            <CurrencyCircleDollar size={18} />
          </Box>

          <Typography variant="body2" fontSize={13} textAlign={"center"}>
            هزینه دریافت آخرین قیمت‌های بازار: <b>{toolPrice}</b> اعتبار
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <PrimaryButton
            isLoading={isLoadingButton}
            endIcon={<ArrowLeft weight="bold" size={20} />}
            onClick={
              type === "1"
                ? handleGetGoldList
                : type === "2"
                ? handleGetCryptoList
                : null
            }
          >
            دریافت آخرین قیمت‌های بازار
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default GenerateCurrenyPriceInformer;
