import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  history: [],
  current: null,
};

export const generateLetterWriter = createAsyncThunk(
  "letterWriter/generateLetterWriter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/letter-writer/requests`,
        data: {
          type: payload.type,
          length: payload.length,
          tone: payload.tone,
          subject: payload.subject,
          repliedMessage: payload.repliedMessage ?? null,
        },
      });

      thunkApi.dispatch(saveCurrentLetterWriter(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reGenerateLetterWriter = createAsyncThunk(
  "letterWriter/reGenerateLetterWriter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/letter-writer/requests/${payload.id}/regenerate`,
      });

      thunkApi.dispatch(saveCurrentLetterWriter(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllLetterWriterHistory = createAsyncThunk(
  "letterWriter/fetchAllLetterWriterHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/letter-writer/requests?page=${payload.page}&limit=${payload.limit}`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchLetterWriter = createAsyncThunk(
  "letterWriter/fetchLetterWriter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/letter-writer/requests/${payload.id}`,
      });

      thunkApi.dispatch(saveCurrentLetterWriter(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteLetterWriter = createAsyncThunk(
  "letterWriter/deleteLetterWriter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/letter-writer/requests`,
        data: { ids: [payload.id] },
      });

      thunkApi.dispatch(cleanupCurrentLetterWriter());

      return payload.id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearAllLetterWriterHistory = createAsyncThunk(
  "letterWriter/clearAllLetterWriterHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/letter-writer/requests`,
        data: { ids: null },
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCurrentLetterWriter = createAction(
  "letterWriter/saveCurrentLetterWriter"
);

export const cleanupCurrentLetterWriter = createAction(
  "letterWriter/cleanupCurrentLetterWriter"
);

const letterWriterSlice = createSlice({
  initialState,
  name: "letterWriter",
  extraReducers: (builder) => {
    builder.addCase(saveCurrentLetterWriter, (state, action) => {
      state.current = action.payload;
    });

    builder.addCase(cleanupCurrentLetterWriter, (state) => {
      state.current = initialState.current;
    });

    builder.addCase(fetchAllLetterWriterHistory.fulfilled, (state, action) => {
      state.history = action.payload?.rows;
    });

    builder.addCase(clearAllLetterWriterHistory.fulfilled, (state) => {
      state.history = [];
    });

    builder.addCase(deleteLetterWriter.fulfilled, (state, action) => {
      state.history = state.history.filter((item) => item?.id !== action);
    });
  },
});

export default letterWriterSlice.reducer;
