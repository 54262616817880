import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  goldList: [],
  cryptoList: [],
};

export const getGoldAndCurrencyPricesList = createAsyncThunk(
  "currencyPriceInformer/getGoldAndCurrencyPricesList",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/currency-price-informer/gold-and-currencies`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getCryptoCurrencyPricesList = createAsyncThunk(
  "currencyPriceInformer/getCryptoCurrencyPricesList",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/currency-price-informer/crypro-currencies`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cleanupGoldAndCurrencyPricesList = createAction(
  "currencyPriceInformer/cleanupGoldAndCurrencyPricesList"
);

export const cleanupCryptoCurrencyPricesList = createAction(
  "currencyPriceInformer/cleanupCryptoCurrencyPricesList"
);

const currencyPriceInformerSlice = createSlice({
  initialState,
  name: "currencyPriceInformer",
  extraReducers: (builder) => {
    builder.addCase(getGoldAndCurrencyPricesList.fulfilled, (state, action) => {
      state.goldList = action.payload;
    });

    builder.addCase(getCryptoCurrencyPricesList.fulfilled, (state, action) => {
      state.cryptoList = action.payload;
    });

    builder.addCase(cleanupGoldAndCurrencyPricesList, (state) => {
      state.goldList = initialState.goldList;
    });

    builder.addCase(cleanupCryptoCurrencyPricesList, (state) => {
      state.cryptoList = initialState.cryptoList;
    });
  },
});

export default currencyPriceInformerSlice.reducer;
