import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  history: [],
  current: null,
};

export const generateSocialContentCreator = createAsyncThunk(
  "socialContentCreator/generateSocialContentCreator",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/social-content-creator/requests`,
        data: {
          type: payload.type,
          length: payload.length,
          tone: payload.tone,
          platform: payload.platform,
          subject: payload.subject,
        },
      });

      thunkApi.dispatch(saveCurrentSocialContentCreator(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reGenerateSocialContentCreator = createAsyncThunk(
  "socialContentCreator/reGenerateSocialContentCreator",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/social-content-creator/requests/${payload.id}/regenerate`,
      });

      thunkApi.dispatch(saveCurrentSocialContentCreator(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllSocialContentCreatorHistory = createAsyncThunk(
  "socialContentCreator/fetchAllSocialContentCreatorHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/social-content-creator/requests?page=${payload.page}&limit=${payload.limit}`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchSocialContentCreator = createAsyncThunk(
  "socialContentCreator/fetchSocialContentCreator",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/social-content-creator/requests/${payload.id}`,
      });

      thunkApi.dispatch(saveCurrentSocialContentCreator(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteSocialContentCreator = createAsyncThunk(
  "socialContentCreator/deleteSocialContentCreator",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/social-content-creator/requests`,
        data: { ids: [payload.id] },
      });

      thunkApi.dispatch(cleanupCurrentSocialContentCreator());

      return payload.id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearAllSocialContentCreatorHistory = createAsyncThunk(
  "socialContentCreator/clearAllSocialContentCreatorHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/social-content-creator/requests`,
        data: { ids: null },
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCurrentSocialContentCreator = createAction(
  "socialContentCreator/saveCurrentSocialContentCreator"
);

export const cleanupCurrentSocialContentCreator = createAction(
  "socialContentCreator/cleanupCurrentSocialContentCreator"
);

const socialContentCreatorSlice = createSlice({
  initialState,
  name: "socialContentCreator",
  extraReducers: (builder) => {
    builder.addCase(saveCurrentSocialContentCreator, (state, action) => {
      state.current = action.payload;
    });

    builder.addCase(cleanupCurrentSocialContentCreator, (state) => {
      state.current = initialState.current;
    });

    builder.addCase(
      fetchAllSocialContentCreatorHistory.fulfilled,
      (state, action) => {
        state.history = action.payload?.rows;
      }
    );

    builder.addCase(clearAllSocialContentCreatorHistory.fulfilled, (state) => {
      state.history = [];
    });

    builder.addCase(deleteSocialContentCreator.fulfilled, (state, action) => {
      state.history = state.history.filter((item) => item?.id !== action);
    });
  },
});

export default socialContentCreatorSlice.reducer;
