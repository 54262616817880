import { combineReducers, configureStore } from "@reduxjs/toolkit";
import toolsPricesSlice from "../features/settings/toolsPricesSlice";
import dreamInterpreterSlice from "../features/dream_interpreter/dreamInterpreterSlice";
import letterWriterSlice from "../features/letter_writer/letterWriterSlice";
import socialContentCreatorSlice from "../features/social_content_creator/socialContentCreatorSlice";
import languageExaminerSlice from "../features/language_examiner/languageExaminerSlice";
import snackbarSlice from "../features/general/snackbarSlice";
import dialogSlice from "../features/general/dialogSlice";
import predictorSlice from "../features/predictor/predictorSlice";
import movieSuggesterSlice from "../features/movie_suggester/movieSuggesterSlice";
import currencyPriceInformerSlice from "../features/currency_price_informer/currencyPriceInformerSlice";

const appReducer = combineReducers({
  toolsPrices: toolsPricesSlice,
  dreamInterpreter: dreamInterpreterSlice,
  letterWriter: letterWriterSlice,
  socialContentCreator: socialContentCreatorSlice,
  languageExaminer: languageExaminerSlice,
  predictor: predictorSlice,
  movieSuggester: movieSuggesterSlice,
  currenyPriceInformer: currencyPriceInformerSlice,
  appSnackbar: snackbarSlice,
  appDialog: dialogSlice,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export const store = configureStore({ reducer: rootReducer });
