import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowLeft,
  ClockCounterClockwise,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { showAppSnackbar } from "../../features/general/snackbarSlice";
import { generateMovieSuggester } from "../../features/movie_suggester/movieSuggesterSlice";
import PrimaryButton from "../../components/General/PrimaryButton";
import {
  movieSuggesterMovieGenre,
  movieSuggesterMovieGenreies,
  movieSuggesterMovieMakerCountries,
} from "../../config/constants";

const GenerateMovieSuggester = () => {
  useEffect(() => {
    window.ChangeTitleChannel?.postMessage(
      JSON.stringify({ title: "پیشنهاد فیلم", hasBack: true })
    );
  }, []);

  const navigate = useNavigate();

  const [type, setType] = useState("0");
  const [genre, setGenre] = useState("0");
  const [country, setCountry] = useState("0");
  const [favoriteMovies, setFavoriteMovies] = useState("");
  const [favoriteActors, setFavoriteActors] = useState("");
  const [favoriteSubject, setFavoriteSubject] = useState("");
  const [suggestionCount, setSuggestionCount] = useState("3");

  const [submitted, setSubmitted] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [toolPrice, setToolPrice] = useState("0");

  const [bottomButtonHeight, setBottomButtonHeight] = useState(0);

  const bottomButtonRef = useRef(null);

  const dispatch = useDispatch();

  const toolsPrices = useSelector((state) => state.toolsPrices.data);

  const currentMovieSuggester = useSelector(
    (state) => state.movieSuggester.current
  );

  useEffect(() => {
    if (toolsPrices) {
      const basePrice = toolsPrices?.movieSuggester?.base ?? 0;

      const suggestionCountPrice =
        (toolsPrices?.movieSuggester?.extra?.suggestionCountCoefficient ?? 0) *
        suggestionCount;

      setToolPrice(basePrice + suggestionCountPrice);
    }
  }, [suggestionCount, toolsPrices]);

  useEffect(() => {
    setBottomButtonHeight(
      bottomButtonRef.current?.getBoundingClientRect().height
    );
  }, []);

  useEffect(() => {
    if (submitted && currentMovieSuggester?.id) {
      navigate(
        routes.movieSuggesterSingle?.replace(":id", currentMovieSuggester?.id),
        {
          replace: true,
        }
      );

      setSubmitted(false);
    }
  }, [currentMovieSuggester?.id, navigate, submitted]);

  const handleGenerate = () => {
    const favoriteMoviesValue = favoriteMovies?.trim();
    const favoriteActorsValue = favoriteActors?.trim();
    const favoriteSubjectValue = favoriteSubject?.trim();

    setIsLoadingButton(true);

    dispatch(
      generateMovieSuggester({
        type: type != "0" ? type : null,
        genre: genre != "0" ? genre : null,
        country: country != "0" ? country : null,
        favoriteMovies: favoriteMoviesValue !== "" ? favoriteMoviesValue : null,
        favoriteActors: favoriteActorsValue !== "" ? favoriteActorsValue : null,
        favoriteSubject:
          favoriteSubjectValue !== "" ? favoriteSubjectValue : null,
        suggestionCount: suggestionCount,
      })
    ).then((res) => {
      setIsLoadingButton(false);

      if (res.error === undefined) {
        setSubmitted(true);

        window.RefetchUserCreditChannel?.postMessage("RefetchUserCredit");
      }
    });
  };

  return (
    <Container maxWidth={"xs"} sx={{ p: 2, position: "relative" }}>
      <Stack gap={2}>
        <Box>
          <Typography fontWeight={600} mb={1.5}>
            نوع فیلم
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            fullWidth
          >
            <MenuItem key={0} value="0">
              همه
            </MenuItem>

            <MenuItem key={1} value="1">
              سینمایی
            </MenuItem>

            <MenuItem key={2} value="2">
              سریال
            </MenuItem>
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            ژانر
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
            fullWidth
          >
            {movieSuggesterMovieGenreies.map((item, i) => (
              <MenuItem key={i} value={i}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            کشور سازنده
          </Typography>

          <Select
            size="medium"
            MenuProps={{ elevation: 1 }}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                bgcolor: "background.paper",
              },
            }}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            fullWidth
          >
            {movieSuggesterMovieMakerCountries.map((item, i) => (
              <MenuItem key={i} value={i}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            موضوع یا فضای خاص
          </Typography>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                bgcolor: "background.paper",
              },
            }}
            type="text"
            multiline
            rows={2}
            variant="outlined"
            placeholder="مثلا: سفر در زمان یا چند دوست در جنگل و ... (اختیاری)"
            fullWidth
            value={favoriteSubject}
            onChange={(e) => setFavoriteSubject(e.target.value)}
          />
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            بازیگر / کارگردان
          </Typography>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                bgcolor: "background.paper",
              },
            }}
            type="text"
            multiline
            rows={2}
            variant="outlined"
            placeholder="نام بازیگر یا کارگردانی که ترجیح میدهید. (اختیاری)"
            fullWidth
            value={favoriteActors}
            onChange={(e) => setFavoriteActors(e.target.value)}
          />
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            فیلم های مورد علاقه شما
          </Typography>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                bgcolor: "background.paper",
              },
            }}
            type="text"
            multiline
            rows={2}
            variant="outlined"
            placeholder="فیلم های مورد علاقه شما که پیش‌تر آنها را تماشا کرده‌اید. (اختیاری)"
            fullWidth
            value={favoriteMovies}
            onChange={(e) => setFavoriteMovies(e.target.value)}
          />
        </Box>

        <Box>
          <Typography fontWeight={600} mb={1.5}>
            تعداد پیشنهاد
          </Typography>

          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                bgcolor: "background.paper",
              },
            }}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 20 } }}
            variant="outlined"
            fullWidth
            value={suggestionCount}
            onChange={(e) => setSuggestionCount(e.target.value)}
          />
        </Box>
      </Stack>

      <Box sx={{ height: bottomButtonHeight }} />

      <Box
        ref={bottomButtonRef}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          p: 2,
          zIndex: 2,
          bgcolor: "background.default",
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} gap={0.5} mb={2}>
          <Box sx={{ color: "primary.main" }} fontSize={0}>
            <CurrencyCircleDollar size={18} />
          </Box>

          <Typography variant="body2" fontSize={13} textAlign={"center"}>
            هزینه دریافت پیشنهاد فیلم: <b>{toolPrice}</b> اعتبار
          </Typography>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Link to={routes.movieSuggesterHistory}>
            <Box sx={{ color: "text.primary" }} fontSize={0}>
              <ClockCounterClockwise size={28} />
            </Box>
          </Link>

          <PrimaryButton
            isLoading={isLoadingButton}
            endIcon={<ArrowLeft weight="bold" size={20} />}
            onClick={handleGenerate}
          >
            دریافت پیشنهاد فیلم
          </PrimaryButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default GenerateMovieSuggester;
