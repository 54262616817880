import { Navigate, createBrowserRouter } from "react-router-dom";
import Root from "../routes/root/Root";
import GenerateDreamInterpreter from "../routes/dream_interpreter/GenerateDreamInterpreter";
import GenerateLetterWriter from "../routes/letter_writer/GenerateLetterWriter";
import GenerateSocialContentCreator from "../routes/social_content_creator/GenerateSocialContentCreator";
import GenerateLanguageExaminer from "../routes/language_examiner/GenerateLanguageExaminer";
import DreamInterpreterSingle from "../routes/dream_interpreter/DreamInterperterSingle";
import DreamInterpreterHistory from "../routes/dream_interpreter/DreamInterpreterHistory";
import LetterWriterSingle from "../routes/letter_writer/LetterWriterSingle";
import LetterWriterHistory from "../routes/letter_writer/LetterWriterHistory";
import SocialContentCreatorSingle from "../routes/social_content_creator/SocialContentCreatorSingle";
import SocialContentCreatorHistory from "../routes/social_content_creator/SocialContentCreatorHistory";
import LanguageExaminerSingle from "../routes/language_examiner/LanguageExaminerSingle";
import LanguageExaminerQuestions from "../routes/language_examiner/LanguageExaminerQuestions";
import LanguageExaminerHistory from "../routes/language_examiner/LanguageExaminerHistory";
import GeneratePredictor from "../routes/predictor/GeneratePredictor";
import PredictorSingle from "../routes/predictor/PredictorSingle";
import PredictorHistory from "../routes/predictor/PredictorHistory";
import GenerateMovieSuggester from "../routes/movie_suggester/GenerateMovieSuggester";
import MovieSuggesterSingle from "../routes/movie_suggester/MovieSuggesterSingle";
import MovieSuggesterHistory from "../routes/movie_suggester/MovieSuggesterHistory";
import GenerateCurrenyPriceInformer from "../routes/currency_price_informer/GenerateCurrenyPriceInformer";
import GoldListResult from "../routes/currency_price_informer/GoldListResult";
import CryptoListResult from "../routes/currency_price_informer/CryptoListResult";

export const routes = {
  root: "/",

  generateDreamInterpreter: "/dream-interpreter/generate",
  dreamInterpreterHistory: "/dream-interpreter/history",
  dreamInterpreterSingle: "/dream-interpreter/history/:id",

  generateLetterWriter: "/letter-writer/generate",
  letterWriterHistory: "/letter-writer/history",
  letterWriterSingle: "/letter-writer/history/:id",

  generateSocialContentCreator: "/social-content-creator/generate",
  socialContentCreatorHistory: "/social-content-creator/history",
  socialContentCreatorSingle: "/social-content-creator/history/:id",

  generateLanguageExaminer: "/language-examiner/generate",
  languageExaminerQuestions: "/language-examiner/questions/:id/:questionId",
  languageExaminerHistory: "/language-examiner/history",
  languageExaminerSingle: "/language-examiner/history/:id",

  generatePredictor: "/predictor/generate",
  predictorHistory: "/predictor/history",
  predictorSingle: "/predictor/history/:id",

  generateMovieSuggester: "/movie-suggester/generate",
  movieSuggesterHistory: "/movie-suggester/history",
  movieSuggesterSingle: "/movie-suggester/history/:id",

  generateCurrencyPriceInformer: "/currency-price-informer/generate",
  goldListResult: "/currency-price-informer/gold-and-currencies",
  cryptoListResult: "/currency-price-informer/crypro-currencies",
};

const router = createBrowserRouter([
  {
    path: routes.root,
    element: <Root />,
  },

  {
    path: "*",
    element: <Navigate to={routes.root} />,
  },

  {
    path: routes.generateDreamInterpreter,
    element: <GenerateDreamInterpreter />,
  },
  {
    path: routes.dreamInterpreterSingle,
    element: <DreamInterpreterSingle />,
  },
  {
    path: routes.dreamInterpreterHistory,
    element: <DreamInterpreterHistory />,
  },

  {
    path: routes.generateLetterWriter,
    element: <GenerateLetterWriter />,
  },
  {
    path: routes.letterWriterSingle,
    element: <LetterWriterSingle />,
  },
  {
    path: routes.letterWriterHistory,
    element: <LetterWriterHistory />,
  },

  {
    path: routes.generateSocialContentCreator,
    element: <GenerateSocialContentCreator />,
  },
  {
    path: routes.socialContentCreatorSingle,
    element: <SocialContentCreatorSingle />,
  },
  {
    path: routes.socialContentCreatorHistory,
    element: <SocialContentCreatorHistory />,
  },

  {
    path: routes.generateLanguageExaminer,
    element: <GenerateLanguageExaminer />,
  },
  {
    path: routes.languageExaminerSingle,
    element: <LanguageExaminerSingle />,
  },
  {
    path: routes.languageExaminerHistory,
    element: <LanguageExaminerHistory />,
  },
  {
    path: routes.languageExaminerQuestions,
    element: <LanguageExaminerQuestions />,
  },

  {
    path: routes.generatePredictor,
    element: <GeneratePredictor />,
  },
  {
    path: routes.predictorSingle,
    element: <PredictorSingle />,
  },
  {
    path: routes.predictorHistory,
    element: <PredictorHistory />,
  },

  {
    path: routes.generateMovieSuggester,
    element: <GenerateMovieSuggester />,
  },
  {
    path: routes.movieSuggesterSingle,
    element: <MovieSuggesterSingle />,
  },
  {
    path: routes.movieSuggesterHistory,
    element: <MovieSuggesterHistory />,
  },

  {
    path: routes.generateCurrencyPriceInformer,
    element: <GenerateCurrenyPriceInformer />,
  },
  {
    path: routes.goldListResult,
    element: <GoldListResult />,
  },
  {
    path: routes.cryptoListResult,
    element: <CryptoListResult />,
  },
]);

export default router;
