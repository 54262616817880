import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/ApiService";

const initialState = {
  history: [],
  current: null,
};

export const generateDreamInterpreter = createAsyncThunk(
  "dreamInterpreter/generateDreamInterpreter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/dream-interpreter/requests`,
        data: { dream: payload.dream },
      });

      thunkApi.dispatch(saveCurrentDreamInterpreter(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reGenerateDreamInterpreter = createAsyncThunk(
  "dreamInterpreter/reGenerateDreamInterpreter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "post",
        url: `/app/tools/dream-interpreter/requests/${payload.id}/regenerate`,
      });

      thunkApi.dispatch(saveCurrentDreamInterpreter(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllDreamInterpreterHistory = createAsyncThunk(
  "dreamInterpreter/fetchAllDreamInterpreterHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/dream-interpreter/requests?page=${payload.page}&limit=${payload.limit}`,
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchDreamInterpreter = createAsyncThunk(
  "dreamInterpreter/fetchDreamInterpreter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "get",
        url: `/app/tools/dream-interpreter/requests/${payload.id}`,
      });

      thunkApi.dispatch(saveCurrentDreamInterpreter(result));

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteDreamInterpreter = createAsyncThunk(
  "dreamInterpreter/deleteDreamInterpreter",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/dream-interpreter/requests`,
        data: { ids: [payload.id] },
      });

      thunkApi.dispatch(cleanupCurrentDreamInterpreter());

      return payload.id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearAllDreamInterpreterHistory = createAsyncThunk(
  "dreamInterpreter/clearAllDreamInterpreterHistory",
  async (payload, thunkApi) => {
    try {
      const result = await ApiService.call({
        method: "delete",
        url: `/app/tools/dream-interpreter/requests`,
        data: { ids: null },
      });

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCurrentDreamInterpreter = createAction(
  "dreamInterpreter/saveCurrentDreamInterpreter"
);

export const cleanupCurrentDreamInterpreter = createAction(
  "dreamInterpreter/cleanupCurrentDreamInterpreter"
);

const dreamInterpreterSlice = createSlice({
  initialState,
  name: "dreamInterpreter",
  extraReducers: (builder) => {
    builder.addCase(saveCurrentDreamInterpreter, (state, action) => {
      state.current = action.payload;
    });

    builder.addCase(cleanupCurrentDreamInterpreter, (state) => {
      state.current = initialState.current;
    });

    builder.addCase(
      fetchAllDreamInterpreterHistory.fulfilled,
      (state, action) => {
        state.history = action.payload?.rows;
      }
    );

    builder.addCase(clearAllDreamInterpreterHistory.fulfilled, (state) => {
      state.history = [];
    });

    builder.addCase(deleteDreamInterpreter.fulfilled, (state, action) => {
      state.history = state.history.filter((item) => item?.id !== action);
    });
  },
});

export default dreamInterpreterSlice.reducer;
